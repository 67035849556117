import { fetchImobiliariaAction } from "./action";
import { ImobiliariaActions } from "./action";
import { call, put } from "redux-saga/effects";
import ImobiliariaAPI from "./../../Data/ImobiliariaAPI";

export function* fetchImobiliarias(action: fetchImobiliariaAction) {
  try {
    const data = yield call(ImobiliariaAPI.fetchImobiliarias, action.payload);

    yield put(ImobiliariaActions.fetchImobiliariaSucceeded(data.data));
  } catch (e) {
    yield put(ImobiliariaActions.fetchImobiliariaFailed(e.message));
  }
}
