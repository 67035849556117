import React, { FC, Fragment } from 'react';
import styles from './Localizacao.module.scss';

export interface ILocalizacao {
    setRef: (value: any) => void;
}

const Localizacao: FC<ILocalizacao> = ({ setRef }) => (
    <Fragment>
        <div className={styles['Localizacao']} ref={setRef}>
            <div className={styles['mapouter']}>
                <iframe
                    frameBorder={0}
                    marginHeight={0}
                    title="MapaLocalizacao"
                    scrolling="no"
                    className={styles['gmap_canvas']}
                    src="https://maps.google.com/maps?q=-17.846667,-50.950183&t=&z=17&ie=UTF8&iwloc=&output=embed"></iframe>
            </div>
        </div>
    </Fragment>
);

export default Localizacao;