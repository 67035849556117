import { call, put, all } from 'redux-saga/effects';
import { fetchLoginAction, LoginActions } from './action';
import UsuarioAPI from './../../../Data/UsuarioAPI';
import { securityService } from '../../../Utils/SecurityService';
import { history } from '../../..';

export function* fetchLogin(action: fetchLoginAction) {
  try {
    const data = yield call(UsuarioAPI.fetchLogin, action.payload);

    yield all([
      securityService.saveSession(data.data.data),
      put(LoginActions.fetchLoginSucceeded(data.data)),
      history.push('/painel')
    ])
  } catch (e) {
    yield put(LoginActions.fetchLoginFailed(e.message));
  }
}
