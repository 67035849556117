import React, { FC, Fragment } from "react";
import styles from "./Menu.module.scss";
import loteFile from "./../../../static/images/Bandeira_Cidade-Jardim.png";
import logoCidadeJardim from "./../../../static/images/LogoCidadeJardim.svg";
import iconInsta from "./../../../static/images/IconInstagram.svg";
import iconFB from "./../../../static/images/IconFacebook.svg";

export interface IMenuItem {
  title: string;
  onClick: () => void;
}

export interface IMenuItems {
  items: IMenuItem[];
}

const Menu: FC<IMenuItems> = ({ items }) => (
  <Fragment>
    <div className={styles["Menu"]}>
      <img alt="Logo" className={styles["logo"]} src={logoCidadeJardim} />
      <img alt="lotes" className={styles["lotes"]} src={loteFile} />
      <div className={styles["items"]}>
        {items.map((item, idx) => (
          <button
            onClick={item.onClick}
            key={`${item.title.trim()}-${idx}`}
            className={styles["item"]}
          >
            <p>{item.title}</p>
            <img
              alt={item.title}
              src={require("./../../../static/images/IconDropdownMenu.svg")}
            />
          </button>
        ))}
      </div>
      <div className={styles["social-media"]}>
        <img
          alt="mapInstaIcon"
          useMap="#instaIcon"
          className={styles["icon"]}
          src={iconInsta}
        />
        <map name="instaIcon">
          <area
            className={styles["point"]}
            target="_BLANK"
            href="https://www.instagram.com/rescidadejardim/?hl=pt-br"
            alt="Instagram"
            title="Instagram"
            coords="15,25,20"
            shape="circle"
          />
        </map>
        <img
          alt="FbIcon"
          useMap="#fbIcon"
          className={styles["icon-fb"]}
          src={iconFB}
        />
        <map name="fbIcon">
          <area
            className={styles["point"]}
            target="_BLANK"
            href="https://www.facebook.com/Residencial-Cidade-Jardim-101310894622784/"
            alt="Facebook"
            title="Facebook"
            coords="20,25,20"
            shape="circle"
          />
        </map>
      </div>
    </div>
  </Fragment>
);

export default Menu;
