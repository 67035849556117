import { call, put, all } from 'redux-saga/effects';
import { fetchSaveNoticiaAction, SaveNoticiaActions } from './action';
import NoticiaAPI from './../../../Data/NoticiaAPI';
import { NoticiaActions } from '../action';

export function* fetchSaveNoticia(action: fetchSaveNoticiaAction) {
  try {
    const data = yield call(NoticiaAPI.fetchSaveNoticia, action.payload);

    yield all([
      put(SaveNoticiaActions.fetchSaveNoticiaSucceeded(data.data)),
      put(NoticiaActions.fetchNoticia({}))
    ]);
  } catch (e) {
    yield put(SaveNoticiaActions.fetchSaveNoticiaFailed(e.message));
  }
}
