import React, { FC } from 'react';
import styles from './../Input.module.scss';
import { FieldMetaState } from 'react-final-form';

export interface IErrorInputMessage extends FieldMetaState<any> { }

export const ErrorInputMessage: FC<IErrorInputMessage> = ({
  error,
  touched
}) => {
  return (
    <div>
      {touched && error && <span className={styles['error']}>{error}</span>}
    </div>
  );
};
