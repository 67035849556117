import { AxiosResponse } from 'axios';
import { Http } from '../Utils/Http';
import { IResponseAPI } from '../Model/IResponseAPI';
import { INoticia } from '../Model/INoticia';
import { IResponseErrorAPI } from '../Model/IResponseErrorAPI';
import { IAPIListResponse } from '../Model/IAPIListResponse';
import { ISearch } from '../Model/ISearch';
import ApiBase from './APIBase';
import { INewNoticia } from '../Model/INewNoticia';

export interface INoticiaAPI {
  fetchMoticias(data: ISearch): Promise<AxiosResponse<IAPIListResponse<INoticia[]> | IResponseErrorAPI>>;
  fetchSaveNoticia(data: INewNoticia): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>>;
}

class NoticiaAPI implements INoticiaAPI {
  async fetchMoticias(data: ISearch): Promise<AxiosResponse<IAPIListResponse<INoticia[]> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    const url = ApiBase.createUrl('/api/Noticia/noticias', data);
    const response = axios.get<IAPIListResponse<INoticia[]> | IResponseErrorAPI>(url);
    return response;
  }
  async fetchSaveNoticia(data: INewNoticia): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    let response;
    if (data.id) {
      response = axios.put<IResponseAPI<boolean> | IResponseErrorAPI>(`/api/Noticia`, data);
    } else {
      const formData = new FormData();
      formData.append('Id', data.id);
      formData.append('Title', data.title);
      formData.append('Descricao', data.descricao);
      formData.append('Data', data.data);
      formData.append('Foto', data.foto, data.foto.name);
      response = axios.post<IResponseAPI<boolean> | IResponseErrorAPI>(`/api/Noticia`, formData);
    }
    return response;
  }
}

const instance = new NoticiaAPI();
export default instance;
