import { createAction, Action, ActionsUnion } from '../../actionHelpers';
import { IResponseErrorAPI } from '../../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../../Model/IResponseAPI';
import { ILoginForm } from '../../../Model/ILoginForm';
import { ILoginResult } from '../../../Model/ILoginResult';

export enum LoginActionKeys {
  FETCH_LOGIN_STARTED = '[LOGIN] FETCH_LOGIN_STARTED',
  FETCH_LOGIN_SUCCEEDED = '[LOGIN] FETCH_LOGIN_SUCCEEDED',
  FETCH_LOGIN_FAILED = '[LOGIN] FETCH_LOGIN_FAILED'
}

export const LoginActions = {
  fetchLogin: (data: ILoginForm): fetchLoginAction =>
    createAction(LoginActionKeys.FETCH_LOGIN_STARTED, data),
  fetchLoginSucceeded: (data: IResponseAPI<ILoginResult>): fetchLoginSuccededAction =>
    createAction(LoginActionKeys.FETCH_LOGIN_SUCCEEDED, data),
  fetchLoginFailed: (error: IResponseErrorAPI): fetchLoginFailedAction =>
    createAction(LoginActionKeys.FETCH_LOGIN_FAILED, error)
};

export type LoginActionUnion = ActionsUnion<typeof LoginActions>;

export type fetchLoginAction = Action<
  LoginActionKeys.FETCH_LOGIN_STARTED,
  ILoginForm
>;
export type fetchLoginSuccededAction = Action<
  LoginActionKeys.FETCH_LOGIN_SUCCEEDED,
  IResponseAPI<ILoginResult>
>;
export type fetchLoginFailedAction = Action<
  LoginActionKeys.FETCH_LOGIN_FAILED,
  IResponseErrorAPI
>;
