import React, { FC } from "react";
import { Route } from "react-router";
import { IRoute } from "./MainSwitch";
import { Menu } from "../../Components/Site/Menu";
import { Footer } from "../../Components/Site/Footer";
import { menuItems } from "../Home/Home";
import styles from "./ShellHost.module.scss";
import { isBrowser } from "react-device-detect";

export const SiteRoute: FC<IRoute> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <div className={styles["ShellHost"]}>
        {isBrowser && <Menu items={[...menuItems]} />}
        <Component {...props} />
        <Footer />
      </div>
    )}
  />
);
