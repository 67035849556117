import React, { Component } from 'react';
import styles from './MenuItem.module.scss';
import { history } from '../../../../index';
import * as H from 'history';
import { securityService } from '../../../../Utils/SecurityService';

export interface IMenuItem {
  name: string;
  to: string;
  permissoes?: string[];
  location?: H.Location<H.LocationState>;
  children?: IMenuItem[];
}

export class MenuItem extends Component<IMenuItem> {
  state = {
    open: false
  };

  handleClick = () => {
    const { children, to } = this.props;
    const { open } = this.state;

    if (children) {
      this.setState({
        open: !open
      });
    } else {
      history.push(`${to}`);
    }
  };

  isActive = (): boolean => {
    const { to } = this.props;
    const { href, origin } = window.location;

    if (to === '') {
      return false;
    }

    const path = href.replace(origin, '');
    return path === to;
  };

  render() {
    const { children, name } = this.props;
    const { open } = this.state;
    const isActive = this.isActive();
    const role = securityService.getUserRole();

    if (!this.props.permissoes || (this.props.permissoes && this.props.permissoes.includes(role))) {
      return (
        <>
          <div
            onClick={this.handleClick}
            className={`${isActive && !children ? styles['active'] : ''} ${
              styles['MenuItem']
              } ${!children ? styles['Menu-Item-Hover'] : null}`}
          >
            <div className={styles['labels']}>
              <p className={styles['text']}>{name}</p>
            </div>
          </div>

          {children && (open || isActive) && children.map((x) => <MenuItem {...x} />)}
        </>
      );
    } else {
      return null;
    }
  }
}
