import { fetchQuadrasAction } from './action';
import { QuadrasActions } from './action';
import { call, put } from 'redux-saga/effects';
import LoteamentoAPI from './../../Data/LoteamentoAPI';

export function* fetchQuadras(action: fetchQuadrasAction) {
  try {
    const data = yield call(LoteamentoAPI.fetchQuadras);

    yield put(QuadrasActions.fetchQuadrasSucceeded(data.data));
  } catch (e) {
    yield put(QuadrasActions.fetchQuadrasFailed(e.message));
  }
}
