import { NoticiaActionUnion, NoticiaActionKeys } from './action';
import { INoticiaState } from './INoticiaState';

const initialState: INoticiaState = {
  data: {
    data: {
      data: []
    },
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const noticiaReducer = (state = initialState, action: NoticiaActionUnion): INoticiaState => {
  switch (action.type) {
    case NoticiaActionKeys.FETCH_NOTICIA_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case NoticiaActionKeys.FETCH_NOTICIA_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case NoticiaActionKeys.FETCH_NOTICIA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default noticiaReducer;
