import React, { Component } from 'react';
import styles from './Login.module.scss';
import FormComponent from '../../../../Components/UI/Form/FormComponent';
import { schema } from './Login.schema';
import { ILoginForm } from '../../../../Model/ILoginForm';
import { ILoginState } from '../../../../Store/Usuario/Login/ILoginState';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { LoginActions } from '../../../../Store/Usuario/Login/action';
import { TextInput } from '../../../../Components/UI/Form/Inputs/TextInput/TextInput';

export interface ILogin {
    fetchLogin: (data: ILoginForm) => void;
    login: ILoginState;
    isLoading: boolean;
}

class Login extends Component<ILogin> {
    state = {
        form: {
            Email: '',
            Senha: ''
        }
    }

    render() {
        return (
            <div className={styles['Login']}>
                <FormComponent
                    schema={schema}
                    initialValues={this.state.form}
                    onSubmit={this.props.fetchLogin}
                    render={({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} className={styles['form']}>
                                <div className={styles['inputs']}>
                                    <TextInput
                                        placeHolder="email"
                                        name="Email"
                                    />
                                    <TextInput
                                        placeHolder="Senha"
                                        type="password"
                                        name="Senha"
                                    />
                                </div>
                                <button className={styles['submit']} disabled={this.props.isLoading} type="submit">
                                    Entrar
                                </button>
                            </form>
                        )
                    }}
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            fetchLogin: LoginActions.fetchLogin
        },
        dispatch
    );
};

const mapStateToProps = (state: any) => {
    const { usuario: { login } } = state;
    return {
        login,
        isLoading: login.isLoading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);