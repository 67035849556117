import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { ILoteamentoDetail } from '../../Model/ILoteamentoDetail';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../Model/IResponseAPI';

export enum QuadraInfoActionKeys {
  FETCH_QUADRA_INFO_STARTED = '[QUADRA_INFO] FETCH_QUADRA_INFO_STARTED',
  FETCH_QUADRA_INFO_SUCCEEDED = '[QUADRA_INFO] FETCH_QUADRA_INFO_SUCCEEDED',
  FETCH_QUADRA_INFO_FAILED = '[QUADRA_INFO] FETCH_QUADRA_INFO_FAILED'
}

export const QuadraInfoActions = {
  fetchQuadraInfo: (quadra: string): fetchQuadraInfoAction =>
    createAction(QuadraInfoActionKeys.FETCH_QUADRA_INFO_STARTED, quadra),
  fetchQuadraInfoSucceeded: (data: IResponseAPI<ILoteamentoDetail>): fetchQuadraInfoSuccededAction =>
    createAction(QuadraInfoActionKeys.FETCH_QUADRA_INFO_SUCCEEDED, data),
  fetchQuadraInfoFailed: (error: IResponseErrorAPI): fetchQuadraInfoFailedAction =>
    createAction(QuadraInfoActionKeys.FETCH_QUADRA_INFO_FAILED, error)
};

export type QuadraInfoActionUnion = ActionsUnion<typeof QuadraInfoActions>;

export type fetchQuadraInfoAction = Action<
  QuadraInfoActionKeys.FETCH_QUADRA_INFO_STARTED,
  string
>;
export type fetchQuadraInfoSuccededAction = Action<
  QuadraInfoActionKeys.FETCH_QUADRA_INFO_SUCCEEDED,
  IResponseAPI<ILoteamentoDetail>
>;
export type fetchQuadraInfoFailedAction = Action<
  QuadraInfoActionKeys.FETCH_QUADRA_INFO_FAILED,
  IResponseErrorAPI
>;
