import React, { FC, Fragment } from 'react';
import styles from './Header.module.scss';

export interface IHeader {
    title: string;
    subTitle?: string;
    hasDownIcon?: boolean;
}

const Header: FC<IHeader> = ({ title, subTitle }) => (
    <Fragment>
        <div className={styles['Header']}>
            <div className={styles['content']}>
                <p>{title}</p>
                <span>{subTitle}</span>
                <img alt="Dropdown" src={require('./../../../static/images/IconDropdownHeader.svg')} />
            </div>
        </div>
    </Fragment >
);

export default Header;