class GridXL {
    static countXL(
      items: number
    ): boolean | 1 | 2 | 7 | 6 | 5 | 4 | 3 | 'auto' | 8 | 9 | 10 | 11 | 12 | undefined {
      switch (items) {
        case 2:
          return 6;
        case 3:
          return 4;
        case 5:
          return 3;
        default:
          return 12;
      }
    }
  }
  
  export default GridXL;
  