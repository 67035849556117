import React, { FC } from 'react';
import { Button as MaterialButton, PropTypes } from '@material-ui/core';

export interface IButton {
  children: String | React.ReactNode;
  className?: string;
  color?: PropTypes.Color;
  loading?: boolean;
  disableFocusRipple?: boolean;
  fullWidth?: boolean;
  href?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'text' | 'outlined' | 'contained';
  onClick: () => void;
}

export const Button: FC<IButton> = (props) => {
  return <MaterialButton {...props}>{props.children}</MaterialButton>;
};
