import {
  SaveImobiliariaActionUnion,
  SaveImobiliariaActionKeys,
} from "./action";
import { ISaveImobiliariaState } from "./ISaveImobiliariaState";

const initialState: ISaveImobiliariaState = {
  data: {
    data: false,
    success: false,
  },
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const saveImobiliariaReducer = (
  state = initialState,
  action: SaveImobiliariaActionUnion
): ISaveImobiliariaState => {
  switch (action.type) {
    case SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default saveImobiliariaReducer;
