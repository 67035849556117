import * as yup from 'yup';

export const schema = yup.object().shape({
  nome: yup.string()
    .required('Informe seu nome'),
  cidade: yup.string()
    .required('Informe sua cidade'),
  email: yup.string()
    .email('Informe um email valido')
    .required('Informe seu email'),
  telefone: yup.string()
    .required('Informe seu telefone')
});
