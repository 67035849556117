import React, { FC } from "react";
import { MainSwitch } from "./MainSwitch";
import styles from "./ShellHost.module.scss";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { NoticiaActions } from "../../Store/Noticia/action";
import { ImobiliariaActions } from "../../Store/Imobiliaria/action";

export interface IShellHost {
  fetchNoticias: (pageSize: number) => void;
  fetchImobiliarias: (pageSize: number) => void;
}

const ShellHost: FC<IShellHost> = (props) => {
  props.fetchNoticias(3);
  props.fetchImobiliarias(10);
  return (
    <div className={styles["ShellHost"]}>
      <MainSwitch />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchNoticias: (pageSize: number) =>
    dispatch(NoticiaActions.fetchNoticia({ pageSize, pageIndex: 0 })),
  fetchImobiliarias: (pageSize: number) =>
    dispatch(ImobiliariaActions.fetchImobiliaria({ pageSize, pageIndex: 0 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShellHost);
