class Money {
  static format = (money: number) => {
    if (money) {
      return money.toFixed(2).replace('.', ',');
    }

    return '0,00';
  };
}

export default Money;
