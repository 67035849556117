import { fetchQuadraInfoAction } from './action';
import { QuadraInfoActions } from './action';
import { call, put } from 'redux-saga/effects';
import LoteamentoAPI from './../../Data/LoteamentoAPI';

export function* fetchLoteamento(action: fetchQuadraInfoAction) {
  try {
    const data = yield call(LoteamentoAPI.fetchLoteamento, action.payload);

    yield put(QuadraInfoActions.fetchQuadraInfoSucceeded(data.data));
  } catch (e) {
    yield put(QuadraInfoActions.fetchQuadraInfoFailed(e.message));
  }
}
