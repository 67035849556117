import { AxiosResponse } from "axios";
import { Http } from "../Utils/Http";
import { IResponseAPI } from "../Model/IResponseAPI";
import { IImobiliaria } from "../Model/IImobiliaria";
import { IResponseErrorAPI } from "../Model/IResponseErrorAPI";
import { IAPIListResponse } from "../Model/IAPIListResponse";
import { ISearch } from "../Model/ISearch";
import ApiBase from "./APIBase";
import { INewImobiliaria } from "../Model/INewImobiliaria";

export interface IImobiliariaAPI {
  fetchImobiliarias(
    data: ISearch
  ): Promise<
    AxiosResponse<IAPIListResponse<IImobiliaria[]> | IResponseErrorAPI>
  >;
  fetchSaveImobiliaria(
    data: INewImobiliaria
  ): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>>;
}

class ImobiliariaAPI implements IImobiliariaAPI {
  async fetchImobiliarias(
    data: ISearch
  ): Promise<
    AxiosResponse<IAPIListResponse<IImobiliaria[]> | IResponseErrorAPI>
  > {
    const axios = await Http.axios();
    const url = ApiBase.createUrl("/api/Imobiliaria", data);
    const response = axios.get<
      IAPIListResponse<IImobiliaria[]> | IResponseErrorAPI
    >(url);
    return response;
  }
  async fetchSaveImobiliaria(
    data: INewImobiliaria
  ): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    const formData = new FormData();
    formData.append("Nome", data.nome);
    formData.append("Foto", data.foto, data.foto.name);
    const response = axios.post<IResponseAPI<boolean> | IResponseErrorAPI>(
      `/api/Imobiliaria`,
      formData
    );
    return response;
  }
}

const instance = new ImobiliariaAPI();
export default instance;
