import { AxiosResponse } from 'axios';
import { Http } from '../Utils/Http';
import { IResponseAPI } from '../Model/IResponseAPI';
import { ILoginForm } from '../Model/ILoginForm';
import { IResponseErrorAPI } from '../Model/IResponseErrorAPI';
import { ISearch } from '../Model/ISearch';
import { IUserInfo } from '../Model/IUserInfo';
import { IAPIErrorResponse } from '../Model/IAPIErrorResponse';
import ApiBase from './APIBase';
import { IAPIListResponse } from '../Model/IAPIListResponse';
import { ISaveUserForm } from '../Model/ISaveUserForm';
import { ILoginResult } from '../Model/ILoginResult';

export interface IUsuarioAPI {
    fetchLogin(data: ILoginForm): Promise<AxiosResponse<IResponseAPI<ILoginResult> | IResponseErrorAPI>>;
    fetchSaveUser(data: ISaveUserForm): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>>;
    fetchAllUsers(data: ISearch): Promise<AxiosResponse<IAPIListResponse<IUserInfo[]> | IAPIErrorResponse>>;
}

class UsuarioAPI implements IUsuarioAPI {
    async fetchLogin(data: ILoginForm): Promise<AxiosResponse<IResponseAPI<ILoginResult> | IResponseErrorAPI>> {
        const axios = await Http.axios();
        const response = axios.post<IResponseAPI<ILoginResult> | IResponseErrorAPI>(`/api/Usuario/authenticate`, data);
        return response;
    }
    async fetchSaveUser(data: ISaveUserForm): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>> {
        const axios = await Http.axios();
        const response = axios.post<IResponseAPI<boolean> | IResponseErrorAPI>(`/api/Usuario`, data);
        return response;
    }
    async fetchAllUsers(data: ISearch): Promise<AxiosResponse<IAPIListResponse<IUserInfo[]> | IAPIErrorResponse>> {
        const axios = await Http.axios();
        const url = ApiBase.createUrl('/api/Usuario/all', data);
        const response = axios.get<IAPIListResponse<IUserInfo[]> | IAPIErrorResponse>(url);
        return response;
    }
}

const instance = new UsuarioAPI();
export default instance;
