import { ILogoutState } from "./ILogoutState";
import { LogoutActionUnion, LogoutActionKeys } from "./actions";

const initalState: ILogoutState = {
    isLoading: false
};

const logoutreducer = (state = initalState, action: LogoutActionUnion): ILogoutState => {
    switch (action.type) {
        case LogoutActionKeys.FETCH_LOGOUT_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case LogoutActionKeys.FETCH_LOGOUT_SUCCEEDED:
            return {
                ...state,
                isLoading: false
            };
        case LogoutActionKeys.FETCH_LOGOUT_FAILED:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};

export default logoutreducer;
