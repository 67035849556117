import React from "react";
import { Switch } from "react-router";
import { Home } from "../Home";
import NoWorkPage from "../NoWorkPage/NoWorkPage";
import Painel from "../Painel/Painel";
import { SiteRoute } from "./SiteRoute";
import { PainelRoute } from "./PainelRoute";
import { NoHeaderRoute } from "./NoHeaderRoute";
import Login from "../Painel/Auth/Login/Login";
import Users from "../Painel/Users/Users";
import Noticias from "../Painel/Noticias/Noticias";
import Quadra from "../Painel/Quadra/Quadra";
import Imobiliaria from "../Painel/Imobiliaria/Imobiliaria";

export interface IRoute {
  path: string;
  component: any;
  title: string;
  routeComponent: any;
  routes?: IRoute[];
}

export const routes: IRoute[] = [
  {
    path: "/painel/login",
    component: Login,
    routeComponent: PainelRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/painel/imobiliarias",
    component: Imobiliaria,
    routeComponent: PainelRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/painel/news",
    component: Noticias,
    routeComponent: PainelRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/painel/users",
    component: Users,
    routeComponent: PainelRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/painel/quadra/:quadra",
    component: Quadra,
    routeComponent: PainelRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/painel",
    component: Painel,
    routeComponent: PainelRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/home/:quadra?/:lote?",
    component: Home,
    routeComponent: SiteRoute,
    title: "Cidade Jardim",
  },
  {
    path: "/",
    component: Home,
    routeComponent: SiteRoute,
    title: "Cidade Jardim",
  },
];

export const MainSwitch = () => (
  <Switch>
    {routes.map((x, idx) => (
      <x.routeComponent key={`route-${idx}`} {...x} />
    ))}
  </Switch>
);
