import { QuadrasActionUnion, QuadrasActionKeys } from './action';
import { IQuadrasState } from './IQuadrasState';

const initialState: IQuadrasState = {
  data: {
    data: [],
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const quadrasReducer = (state = initialState, action: QuadrasActionUnion): IQuadrasState => {
  switch (action.type) {
    case QuadrasActionKeys.FETCH_QUADRAS_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case QuadrasActionKeys.FETCH_QUADRAS_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case QuadrasActionKeys.FETCH_QUADRAS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default quadrasReducer;
