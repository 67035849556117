import React, { Component } from 'react';
import styles from './Users.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { History } from 'history';
import { schema } from './User.schema';
import { Grid } from '@material-ui/core';
import { ITableData } from '../../../Components/UI/Table/Interfaces/ITableData';
import { THeadBuilder } from '../../../Components/UI/Table/Builder/THeadBuilder';
import { NoData } from '../../../Components/UI/NoData/NoData';
import { Button } from '../../../Components/UI/Button';
import { Loading } from '../../../Components/UI/Loading/Loading';
import TableComponent from '../../../Components/UI/Table/TableComponent';
import FormComponent from '../../../Components/UI/Form/FormComponent';
import { TextInput } from '../../../Components/UI/Form/Inputs/TextInput/TextInput';
import { AllUsersActions } from '../../../Store/Usuario/AllUsers/actions';
import { ISearch } from '../../../Model/ISearch';
import { IAllUsersState } from '../../../Store/Usuario/AllUsers/IAllUsersState';
import { SelectInput } from '../../../Components/UI/Form/Inputs/SelectInput/SelectInput';

export interface IUsers {
  isLoading: boolean;
  history: History;
  fetchUsers: (data: ISearch) => void;
  users: IAllUsersState;
}

class Users extends Component<IUsers> {
  componentDidMount() {
    this.props.fetchUsers({});
  }

  state = {
    registering: false,
    atualId: undefined,
    atualItem: undefined,
    form: {
      Nome: '',
      Email: '',
      Role: '',
      Senha: ''
    }
  }

  openRegister = () => {
    this.setState({
      atualId: undefined,
      atualItem: undefined,
      registering: true
    });
  }

  resetRegister = () => {
    this.setState({
      atualId: undefined,
      atualItem: undefined,
      registering: false
    });
  }

  configTable = (): ITableData => {
    return {
      className: styles['bodyTable'],
      keyColumnName: 'id',
      onNavigate: (pageSize: number, pageIndex: number) => {
        this.props.fetchUsers({ pageSize, pageIndex })
      },
      pageIndex: this.props.users.result.data.pageIndex,
      pageSize: this.props.users.result.data.pageSize,
      total: this.props.users.result.data.totalResult,
      HeadData: new THeadBuilder()
        .addCommunHead('nome', 'Nome')
        .addCommunHead('email', 'Email')
        .addCommunHead('role', 'Permissão')
        .build(),
      items: this.props.users.result.data.data
    };
  };

  render() {
    const initialValues = () => {
      if (this.state.atualItem) {
        return this.state.atualItem
      } else {
        return this.state.form;
      }
    }
    return (
      <div className={styles['Users']}>
        <Loading isLoading={this.props.isLoading}>
          {!this.state.registering && <div className={styles['nova-raca']}>
            <Button
              className={styles['button']}
              size="small"
              variant="contained"
              onClick={() => this.openRegister()}>Novo Usuário</Button>
          </div>}
          {this.props.users.result.data.data.length === 0 ?
            (
              <NoData />
            ) :
            (
              <div className={styles['content']}>
                <div className={styles['table']} style={{
                  width: this.state.registering ? 'auto' : '100%'
                }}>
                  <TableComponent {...this.configTable()} />
                </div>
                {this.state.registering &&
                  <div className={styles['register-area']}>
                    <div className={styles['close-register']}>
                      <Button onClick={() => this.resetRegister()} >Fechar</Button>
                    </div>
                    <FormComponent
                      initialValues={initialValues()}
                      schema={schema}
                      onSubmit={(values) => {
                      }}
                      render={({ values, handleSubmit }) => {
                        return (
                          <div className={styles['form']}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextInput
                                  name="Nome"
                                  placeHolder="Informe o Nome"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextInput
                                  name="Email"
                                  placeHolder="Informe o email"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <SelectInput
                                  name="Role"
                                  items={
                                    [
                                      {
                                        label: 'Admin',
                                        value: 'Admin'
                                      },
                                      {
                                        label: 'Corretor',
                                        value: 'User'
                                      }
                                    ]
                                  }
                                  itemLabel="label"
                                  itemValue="value"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextInput
                                  name="Senha"
                                  type="password"
                                  placeHolder="Informe a Senha"
                                />
                              </Grid>
                            </Grid>
                            <div className={styles['submit-area']}>
                              <Button
                                className={styles['button']}
                                variant="contained"
                                onClick={handleSubmit}>
                                Salvar
                              </Button>
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                }
              </div>
            )
          }
        </Loading>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  const { usuario: { all } } = state;
  return {
    users: all,
    isLoading: all.isLoading
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchUsers: AllUsersActions.fetchAllUsers
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);