import { combineReducers } from "redux";
import quadraInfo from "./QuadraInfo/reducer";
import noticias from "./Noticia/reducer";
import solicitaLote from "./SolicitaLote/reducer";
import saveNoticia from "./Noticia/Save/reducer";
import login from "./Usuario/Login/reducer";
import allUsers from "./Usuario/AllUsers/reducer";
import logout from "./Usuario/Logout/reducer";
import quadras from "./Quadras/reducer";
import updateLote from "./UpdateLoteDisponivel/reducer";
import updateQuadra from "./QuadraUpdate/reducer";
import saveImobiliaria from "./Imobiliaria/Save/reducer";
import allImobiliaria from "./Imobiliaria/reducer";
import saveUsuario from "./Usuario/SaveUser/reducer";

const imobiliariReducer = combineReducers({
  all: allImobiliaria,
  save: saveImobiliaria,
});

const usuarioReducer = combineReducers({
  login,
  all: allUsers,
  logout,
  save: saveUsuario,
});

export const rootReducer = combineReducers({
  quadraInfo,
  noticias,
  solicitaLote,
  saveNoticia,
  usuario: usuarioReducer,
  quadras,
  updateLote,
  updateQuadra,
  imobiliaria: imobiliariReducer,
});
