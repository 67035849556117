import { call, put, all } from 'redux-saga/effects';
import UsuarioAPI from './../../../Data/UsuarioAPI';
import { fetchAllUsersAction, AllUsersActions } from './actions';

export function* fetchAllUsers(action: fetchAllUsersAction) {
    try {
        const { data } = yield call(UsuarioAPI.fetchAllUsers, action.payload);

        yield all([
            put(AllUsersActions.fetchAllUsersSucceeded(data)),
        ]);

    } catch (e) {
        yield put(AllUsersActions.fetchAllUsersFailed(e));
    }
}
