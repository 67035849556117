import { SaveNoticiaActionUnion, SaveNoticiaActionKeys } from './action';
import { ISaveNoticiaState } from './ISaveNoticiaState';

const initialState: ISaveNoticiaState = {
  data: {
    data: false,
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const saveNoticiaReducer = (state = initialState, action: SaveNoticiaActionUnion): ISaveNoticiaState => {
  switch (action.type) {
    case SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default saveNoticiaReducer;
