import React, { FC } from "react";
import { Route, Redirect } from "react-router";
import { IRoute } from "./MainSwitch";
import styles from "./ShellHost.module.scss";
import { securityService } from "../../Utils/SecurityService";
import { Topbar, Leftbar } from "../../Components/Painel";
import Role from "../../Model/Enumeradores/Role";

export const PainelRoute: FC<IRoute> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const loginPath = "/painel/login";
      const isAuthenticated = securityService.isAuthenticated();
      if (!isAuthenticated && props.location.pathname !== loginPath) {
        return (
          <Redirect
            to={{ pathname: loginPath, state: { from: props.location } }}
          />
        );
      } else if (isAuthenticated && props.location.pathname === loginPath) {
        return <Redirect to={{ pathname: "/painel" }} />;
      } else if (!isAuthenticated) {
        return <Component {...props} />;
      } else {
        return (
          <div className={styles["ShellHost"]}>
            <Topbar />
            <main className={styles["main"]}>
              <Leftbar
                items={[
                  {
                    name: "Home",
                    to: "/painel",
                    permissoes: [Role.Admin, Role.User],
                  },
                  {
                    name: "Noticias",
                    to: "/painel/news",
                    permissoes: [Role.Admin],
                  },
                  {
                    name: "Imobiliarias",
                    to: "/painel/imobiliarias",
                    permissoes: [Role.Admin],
                  },
                ]}
                bottomItems={[]}
              />
              <div className={styles["content"]}>
                <Component {...props} />
              </div>
            </main>
          </div>
        );
      }
    }}
  />
);
