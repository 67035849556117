import { ITHead } from '../Interfaces/ITHead';

export class THeadBuilder {
  heads: ITHead[];

  constructor() {
    this.heads = [];
  }

  addCommunHead = (name: string, exibitionName: string): THeadBuilder => {
    const newHead: ITHead = {
      name: name,
      exibitionName: exibitionName,
      isStatusColumn: false,
      columnLabelValue: '',
      columnStatusValue: '',
      isImage: false
    };
    this.heads.push(newHead);
    return this;
  };

  addImageHead = (name: string, exibitionName: string): THeadBuilder => {
    const newHead: ITHead = {
      name: name,
      exibitionName: exibitionName,
      isStatusColumn: false,
      columnLabelValue: '',
      columnStatusValue: '',
      isImage: true
    };
    this.heads.push(newHead);
    return this;
  };

  addStatusHead = (
    exibitionName: string,
    columnLabelValue: string,
    columnStatusValue: string
  ): THeadBuilder => {
    const newHead: ITHead = {
      name: '',
      exibitionName: exibitionName,
      columnLabelValue: columnLabelValue,
      isStatusColumn: true,
      columnStatusValue: columnStatusValue,
      isImage: false
    };
    this.heads.push(newHead);
    return this;
  };

  build = (): ITHead[] => this.heads;
}
