import { createAction, Action, ActionsUnion } from '../../actionHelpers';

export enum LogoutActionKeys {
    FETCH_LOGOUT_STARTED = '[LOGOUT] FETCH_LOGOUT_STARTED',
    FETCH_LOGOUT_SUCCEEDED = '[LOGOUT] FETCH_LOGOUT_SUCCEEDED',
    FETCH_LOGOUT_FAILED = '[LOGOUT] FETCH_LOGOUT_FAILED'
}

export const LogoutActions = {
    fetchLogout: (): fetchLogoutAction =>
        createAction(LogoutActionKeys.FETCH_LOGOUT_STARTED),
    fetchLogoutSucceeded: (): fetchLogoutSuccededAction =>
        createAction(LogoutActionKeys.FETCH_LOGOUT_SUCCEEDED),
    fetchLogoutFailed: (): fetchLogoutFailedAction =>
        createAction(LogoutActionKeys.FETCH_LOGOUT_FAILED)
};

export type LogoutActionUnion = ActionsUnion<typeof LogoutActions>;

export type fetchLogoutAction = Action<LogoutActionKeys.FETCH_LOGOUT_STARTED>;
export type fetchLogoutSuccededAction = Action<
    LogoutActionKeys.FETCH_LOGOUT_SUCCEEDED
>;
export type fetchLogoutFailedAction = Action<
    LogoutActionKeys.FETCH_LOGOUT_FAILED
>;