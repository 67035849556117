import React, { FC } from 'react';
import { Field } from 'react-final-form';
import styles from './../Input.module.scss';
import { ErrorInputMessage } from '../ErrorInputMessage/ErrorInputMessage';

export interface ISelectInput {
  className?: string;
  name: string;
  placeHolder?: string;
  disabled?: boolean;
  items: any[];
  itemLabel: string;
  label?: string;
  itemValue: string;
  onChangeVal?: (value: any) => void;
}

export const SelectInput: FC<ISelectInput> = ({
  className,
  name,
  placeHolder,
  disabled,
  itemLabel,
  itemValue,
  items,
  onChangeVal,
  label
}) => {
  return (
    <div className={`${styles['Input']} ${className}`}>
      <Field name={name}>
        {({ input, meta }) => (
          <div className={styles['SelectInput']}>
            {label && <label>{label}</label>}
            <select
              {...input}
            >
              <option value="" disabled >Selecione...</option>
              {items && items.map((x: any) => {
                return <option value={x[itemValue]} >{x[itemLabel]}</option>
              })}
            </select>
            <ErrorInputMessage {...meta} />
          </div>
        )}
      </Field>
    </div>
  );
};
