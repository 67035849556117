import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { IUpdateLote } from '../../Model/IUpdateLote';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../Model/IResponseAPI';

export enum UpdateLoteActionKeys {
  FETCH_UPDATE_LOTE_STARTED = '[UPDATE_LOTE] FETCH_UPDATE_LOTE_STARTED',
  FETCH_UPDATE_LOTE_SUCCEEDED = '[UPDATE_LOTE] FETCH_UPDATE_LOTE_SUCCEEDED',
  FETCH_UPDATE_LOTE_FAILED = '[UPDATE_LOTE] FETCH_UPDATE_LOTE_FAILED'
}

export const UpdateLoteActions = {
  fetchUpdateLote: (data: IUpdateLote): fetchUpdateLoteAction =>
    createAction(UpdateLoteActionKeys.FETCH_UPDATE_LOTE_STARTED, data),
  fetchUpdateLoteSucceeded: (data: IResponseAPI<boolean>): fetchUpdateLoteSuccededAction =>
    createAction(UpdateLoteActionKeys.FETCH_UPDATE_LOTE_SUCCEEDED, data),
  fetchUpdateLoteFailed: (error: IResponseErrorAPI): fetchUpdateLoteFailedAction =>
    createAction(UpdateLoteActionKeys.FETCH_UPDATE_LOTE_FAILED, error)
};

export type UpdateLoteActionUnion = ActionsUnion<typeof UpdateLoteActions>;

export type fetchUpdateLoteAction = Action<
  UpdateLoteActionKeys.FETCH_UPDATE_LOTE_STARTED,
  IUpdateLote
>;
export type fetchUpdateLoteSuccededAction = Action<
  UpdateLoteActionKeys.FETCH_UPDATE_LOTE_SUCCEEDED,
  IResponseAPI<boolean>
>;
export type fetchUpdateLoteFailedAction = Action<
  UpdateLoteActionKeys.FETCH_UPDATE_LOTE_FAILED,
  IResponseErrorAPI
>;
