export enum IColumunStatusValue {
  active = 1,
  success = 2,
  warning = 3,
  negociacao = 4,
  multiplos = 5,
  recusado = 6
}

export interface ITHead {
  name: string;
  exibitionName: string;
  isStatusColumn: boolean;
  columnStatusValue: string;
  columnLabelValue: string;
  isImage: boolean;
}
