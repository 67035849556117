import { IAllUsersState } from "./IAllUsersState";
import { AllUsersActionUnion, AllUsersActionKeys } from "./actions";

const initalState: IAllUsersState = {
    result: {
        data: {
            data: []
        },
        success: true
    },
    error: {
        success: false,
        error: ''
    },
    isLoading: false
};

const allUsersreducer = (state = initalState, action: AllUsersActionUnion): IAllUsersState => {
    switch (action.type) {
        case AllUsersActionKeys.FETCH_ALL_USERS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case AllUsersActionKeys.FETCH_ALL_USERS_SUCCEEDED:
            const result = action.payload;
            return {
                ...state,
                result,
                isLoading: false
            };
        case AllUsersActionKeys.FETCH_ALL_USERS_FAILED:
            const error = action.payload as any;
            return {
                ...state,
                ...error,
                isLoading: false
            };
        default:
            return state;
    }
};

export default allUsersreducer;
