import React, { FC } from 'react';
import styles from './NoData.module.scss';

export interface INoData {
    msg?: string;
    className?: string;
}

export const NoData: FC<INoData> = ({ msg, className }) => (
    <div className={`${styles['NoData']} ${className}`}>
        <div className={styles['content']}>
            <p className={styles['msg']}>Nenhum dado encontrado.</p>
            <p className={styles['error']}>{msg}</p>
        </div>
    </div>
);
