import { fetchSolicitarLoteAction } from './action';
import { SolicitarLoteActions } from './action';
import { call, put } from 'redux-saga/effects';
import LoteamentoAPI from './../../Data/LoteamentoAPI';

export function* fetchSolicitarLote(action: fetchSolicitarLoteAction) {
  try {
    const data = yield call(LoteamentoAPI.fetchSolicitaLote, action.payload);

    yield put(SolicitarLoteActions.fetchSolicitarLoteSucceeded(data.data));
  } catch (e) {
    yield put(SolicitarLoteActions.fetchSolicitarLoteFailed(e.message));
  }
}
