import React, { Component } from 'react';
import styles from './TableComponents.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableRowComponent from './TableRowComponent/TableRowComponent';
import { ITableData } from './Interfaces/ITableData';
import TableHaderComponent from './TableHaderComponent/TableHaderComponent';
import { TableFooter, TablePagination } from '@material-ui/core';
import TableChildrenComponent from './TableChildrenComponent/TableChildrenComponent';

class TableComponent extends Component<ITableData> {
  state = {
    pageSize: this.props.pageSize || 10,
    pageIndex: this.props.pageIndex || 0,
    total: this.props.total || this.props.items.length
  }

  getItemName = (headName: string, item: any): string => {
    const headElementArray: string[] = headName.split('.');
    if (headElementArray.length === 1) {
      const value = item[headElementArray[0].trim()];
      return value;
    } else {
      return this.verifyInnerItems(headElementArray, item);
    }
  };

  verifyInnerItems = (headElement: string[], item: any) => {
    let pointer = item;
    for (let index = 0; index < headElement.length; index++) {
      const element = headElement[index].trim();
      pointer = pointer[element];
    }
    return pointer;
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({
      pageSize: this.state.pageSize,
      pageIndex: newPage
    });
    this.navigate(this.state.pageSize, newPage);
  };

  handleChangeRowsPerPage = (event: any) => {
    const pageSize = parseInt(event.target.value, 10);
    this.setState({
      pageSize,
      pageIndex: this.state.pageIndex
    });
    this.navigate(pageSize, 0);
  };

  navigate = (pageSize: number = this.state.pageSize, pageIndex: number = this.state.pageIndex) => {
    if (this.props.onNavigate) {
      this.props.onNavigate(pageSize, pageIndex);
    }
  }

  render() {
    return (
      <div className={styles['TableComponent']}>
        {this.props.withHeader && this.props.headerData && (
          <TableHaderComponent {...this.props.headerData} />
        )}
        <Table className={styles['table']}>
          <TableHead className={styles['THead']}>
            <TableRow className={styles['row-header']}>
              {this.props.HeadData.map((item, idx) => (
                <TableCell key={`cell_${idx}`}>{item.exibitionName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={`${this.props.className} ${styles['TBody']}`}>
            {(!this.props.items || this.props.items.length <= 0) &&
              <div className={styles['no-results']}>
                <p>Nenhum resultado encontrado</p>
              </div>
            }
            {this.props.items.map((item, index) => {
              return [
                <TableRowComponent
                  key={`outer_row_${index}`}
                  HeadData={this.props.HeadData}
                  clickRow={this.props.clickOnRow}
                  item={item}
                  index={index}
                  isChildren={false}
                  getItemName={this.getItemName}
                />,
                <TableChildrenComponent
                  key={`inner_row_${index}`}
                  clickRow={this.props.clickChildrenRow}
                  HeadData={this.props.HeadData}
                  item={item}
                  getItemName={this.getItemName}
                />
              ]
            })}
          </TableBody>
          {this.props.onNavigate && <TableFooter className={styles['TFooter']}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100, { label: 'Todos', value: this.props.total || 0 }]}
                labelRowsPerPage="Items por pagina"
                colSpan={3}
                count={this.props.total || 0}
                rowsPerPage={this.state.pageSize}
                page={this.state.pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>}
        </Table>
      </div>
    );
  }
}

export default TableComponent;
