import { createAction, Action, ActionsUnion } from '../../actionHelpers';
import { IResponseErrorAPI } from '../../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../../Model/IResponseAPI';
import { ISaveUserForm } from '../../../Model/ISaveUserForm';

export enum SaveUserActionKeys {
  FETCH_SAVE_USER_STARTED = '[SAVE_USER] FETCH_SAVE_USER_STARTED',
  FETCH_SAVE_USER_SUCCEEDED = '[SAVE_USER] FETCH_SAVE_USER_SUCCEEDED',
  FETCH_SAVE_USER_FAILED = '[SAVE_USER] FETCH_SAVE_USER_FAILED'
}

export const SaveUserActions = {
  fetchSaveUser: (data: ISaveUserForm): fetchSaveUserAction =>
    createAction(SaveUserActionKeys.FETCH_SAVE_USER_STARTED, data),
  fetchSaveUserSucceeded: (data: IResponseAPI<boolean>): fetchSaveUserSuccededAction =>
    createAction(SaveUserActionKeys.FETCH_SAVE_USER_SUCCEEDED, data),
  fetchSaveUserFailed: (error: IResponseErrorAPI): fetchSaveUserFailedAction =>
    createAction(SaveUserActionKeys.FETCH_SAVE_USER_FAILED, error)
};

export type SaveUserActionUnion = ActionsUnion<typeof SaveUserActions>;

export type fetchSaveUserAction = Action<
  SaveUserActionKeys.FETCH_SAVE_USER_STARTED,
  ISaveUserForm
>;
export type fetchSaveUserSuccededAction = Action<
  SaveUserActionKeys.FETCH_SAVE_USER_SUCCEEDED,
  IResponseAPI<boolean>
>;
export type fetchSaveUserFailedAction = Action<
  SaveUserActionKeys.FETCH_SAVE_USER_FAILED,
  IResponseErrorAPI
>;
