import { call, put, all } from "redux-saga/effects";
import { fetchSaveImobiliariaAction, SaveImobiliariaActions } from "./action";
import ImobiliariaAPI from "./../../../Data/ImobiliariaAPI";
import { ImobiliariaActions } from "../action";

export function* fetchSaveImobiliaria(action: fetchSaveImobiliariaAction) {
  try {
    const data = yield call(
      ImobiliariaAPI.fetchSaveImobiliaria,
      action.payload
    );

    yield all([
      put(SaveImobiliariaActions.fetchSaveImobiliariaSucceeded(data.data)),
      put(ImobiliariaActions.fetchImobiliaria({})),
    ]);
  } catch (e) {
    yield put(SaveImobiliariaActions.fetchSaveImobiliariaFailed(e.message));
  }
}
