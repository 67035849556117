import React, { FC } from 'react';
import TableRowComponent from '../TableRowComponent/TableRowComponent';
import { ITHead } from '../Interfaces/ITHead';
import styles from '../TableComponents.module.scss';

export interface ITableChildrenComponent {
    item: any;
    HeadData: ITHead[];
    getItemName: Function;
    clickRow?: (item: any) => void;
}

const TableChildrenComponent: FC<ITableChildrenComponent> = ({
    item,
    HeadData,
    getItemName,
    clickRow
}) => (
        <div
            style={{ display: item.isOpen ? 'block' : 'none' }}
            className={styles['children-table']}
        >
            <p className={styles['vinculoCarregamento']}>
                Lotes da quadra
                <span>#{getItemName(HeadData[0].name, item)}</span>
            </p>
            {item.children &&
                item.children.map((children: any, childrenIdx: number) => (
                    <TableRowComponent
                        className={styles['children-row']}
                        clickRow={() => clickRow && clickRow(children)}
                        getItemName={getItemName}
                        key={`row_children_${childrenIdx}`}
                        HeadData={HeadData}
                        item={children}
                        index={childrenIdx}
                        isChildren={true}
                    />
                ))}
        </div>
    );

export default TableChildrenComponent;