import React, { FC } from 'react';
import { connect } from 'react-redux';
import styles from './TopBar.module.scss';

export interface ITopbar {
}

const Topbar: FC<ITopbar> = (props) => (
  <header className={styles['Topbar']}>

  </header>
);

const mapStateToProps = (state: any) => {
  return {
  };
};

export default connect(mapStateToProps)(Topbar);
