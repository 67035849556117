import React, { FC, Fragment } from 'react';
import { Field } from 'react-final-form';
import styles from './../Input.module.scss';
import { ErrorInputMessage } from '../ErrorInputMessage/ErrorInputMessage';

export interface ITextInput {
  className?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  disabled?: boolean;
  type?: 'number' | 'text' | 'password' | 'textarea' | 'file' | 'date';
  onChangeVal?: (value: any) => void;
}

export const TextInput: FC<ITextInput> = ({
  className,
  name,
  placeHolder,
  disabled,
  type,
  onChangeVal,
  label
}) => {
  return (
    <div className={`${styles['Input']} ${className}`}>
      <Field name={name}>
        {({ input, meta }) => (
          <Fragment>
            {label && <label>{label}</label>}
            {type !== 'textarea' &&
              <input
                {...input}
                onChange={(change) => {
                  input.onChange(change);
                  if (onChangeVal) { onChangeVal(change); }
                }}
                disabled={disabled}
                type={type}
                placeholder={placeHolder}
              />}
            {type === 'textarea' &&
              <textarea
                {...input}
                rows={4} cols={50}
                disabled={disabled}
                onChange={(change) => {
                  input.onChange(change);
                  if (onChangeVal) { onChangeVal(change); }
                }}
                placeholder={placeHolder}
              ></textarea>}
            <ErrorInputMessage {...meta} />
          </Fragment>
        )}
      </Field>
    </div>
  );
};
