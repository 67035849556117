import { SaveUserActionUnion, SaveUserActionKeys } from './action';
import { ISaveUserState } from './ISaveUserState';

const initialState: ISaveUserState = {
  data: {
    data: false,
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const saveUserReducer = (state = initialState, action: SaveUserActionUnion): ISaveUserState => {
  switch (action.type) {
    case SaveUserActionKeys.FETCH_SAVE_USER_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case SaveUserActionKeys.FETCH_SAVE_USER_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case SaveUserActionKeys.FETCH_SAVE_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default saveUserReducer;
