import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router";
import { ShellHost } from "./Pages/ShellHost";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { store } from "./Store";

export const history = createBrowserHistory();

const script = document.createElement("script");

script.src = "/static/js/cross-origin-local-storage.js";
script.async = true;

document.body.appendChild(script);

const root = document.getElementById("root") as HTMLElement;
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ShellHost />
    </Router>
  </Provider>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
