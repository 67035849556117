import React, { Component } from "react";
import styles from "./Imobiliaria.module.scss";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ISearch } from "../../../Model/ISearch";
import { THeadBuilder } from "../../../Components/UI/Table/Builder/THeadBuilder";
import { ITableData } from "../../../Components/UI/Table/Interfaces/ITableData";
import { Loading } from "../../../Components/UI/Loading/Loading";
import { Button } from "../../../Components/UI/Button";
import { NoData } from "../../../Components/UI/NoData/NoData";
import TableComponent from "../../../Components/UI/Table/TableComponent";
import FormComponent from "../../../Components/UI/Form/FormComponent";
import { schema } from "./Imobiliaria.schema";
import { Grid } from "@material-ui/core";
import { TextInput } from "../../../Components/UI/Form/Inputs/TextInput/TextInput";
import { ImobiliariaActions } from "../../../Store/Imobiliaria/action";
import { SaveImobiliariaActions } from "../../../Store/Imobiliaria/Save/action";
import { INewImobiliaria } from "../../../Model/INewImobiliaria";
import { IImobiliariaState } from "../../../Store/Imobiliaria/IImobiliariaState";
import { ISaveImobiliariaState } from "../../../Store/Imobiliaria/Save/ISaveImobiliariaState";

export interface IImobiliaria {
  isLoading: boolean;
  imobiliaria: IImobiliariaState;
  save: ISaveImobiliariaState;
  fetchSalvarImobiliaria: (data: INewImobiliaria) => void;
  fetchImobiliaria: (data: ISearch) => void;
  history: History;
}

class Imobiliaria extends Component<IImobiliaria> {
  componentDidMount() {
    this.props.fetchImobiliaria({ pageSize: 10 });
  }

  state = {
    registering: false,
    atualId: undefined,
    atualItem: undefined,
    form: {
      nome: "",
      foto: "",
      imageVal: "",
    },
  };

  configTable = (): ITableData => {
    return {
      className: styles["bodyTable"],
      keyColumnName: "id",
      pageSize: this.props.imobiliaria.data.data.pageSize,
      pageIndex: this.props.imobiliaria.data.data.pageIndex,
      total: this.props.imobiliaria.data.data.totalResult,
      HeadData: new THeadBuilder().addCommunHead("nome", "Nome").build(),
      items: this.props.imobiliaria.data.data.data,
    };
  };

  openRegister = (id?: string) => {
    this.setState({
      atualId: undefined,
      atualItem: undefined,
      registering: true,
    });
  };

  resetRegister = () => {
    this.setState({
      atualId: undefined,
      atualItem: undefined,
      registering: false,
    });
  };

  render() {
    const initialValues = () => {
      if (this.state.atualItem) {
        return this.state.atualItem;
      } else {
        return this.state.form;
      }
    };
    return [
      <div className={styles["Imobiliaria"]}>
        <Loading isLoading={this.props.isLoading}>
          {!this.state.registering && (
            <div className={styles["nova-raca"]}>
              <Button
                className={styles["button"]}
                size="small"
                variant="contained"
                onClick={() => this.openRegister()}
              >
                Nova Imobiliaria
              </Button>
            </div>
          )}
          <div className={styles["content"]}>
            {this.props.imobiliaria.data.data.data.length === 0 ? (
              <NoData />
            ) : (
              <div
                className={styles["table"]}
                style={{
                  width: this.state.registering ? "auto" : "100%",
                }}
              >
                <TableComponent {...this.configTable()} />
              </div>
            )}
            {this.state.registering && (
              <div className={styles["register-area"]}>
                <div className={styles["close-register"]}>
                  <Button onClick={() => this.resetRegister()}>Fechar</Button>
                </div>
                <FormComponent
                  initialValues={initialValues()}
                  schema={schema}
                  onSubmit={(values) => {
                    this.props.fetchSalvarImobiliaria({
                      ...values,
                      foto: values.imageVal,
                    });
                    if (this.state.atualId) {
                      this.resetRegister();
                    }
                  }}
                  render={({
                    values,
                    form: {
                      mutators: { addValue },
                    },
                    handleSubmit,
                  }) => {
                    return (
                      <div className={styles["form"]}>
                        {!this.state.atualId && (
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextInput
                                name="foto"
                                type="file"
                                onChangeVal={(value) => {
                                  addValue("imageVal", value.target.files[0]);
                                }}
                                placeHolder="Informe a Imagem"
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextInput
                              name="nome"
                              placeHolder="Informe o Nome da Imobiliaria"
                            />
                          </Grid>
                        </Grid>
                        <div className={styles["submit-area"]}>
                          <Button
                            className={styles["button"]}
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            Salvar
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </Loading>
      </div>,
    ];
  }
}

const mapStateToProps = (state: any) => {
  const {
    imobiliaria: { all, save },
  } = state;
  return {
    imobiliaria: all,
    save,
    isLoading: all.isLoading || save.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchImobiliaria: ImobiliariaActions.fetchImobiliaria,
      fetchSalvarImobiliaria: SaveImobiliariaActions.fetchSaveImobiliaria,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Imobiliaria);
