import { createAction, Action, ActionsUnion } from "../../actionHelpers";
import { IResponseErrorAPI } from "../../../Model/IResponseErrorAPI";
import { IResponseAPI } from "../../../Model/IResponseAPI";
import { INewImobiliaria } from "../../../Model/INewImobiliaria";

export enum SaveImobiliariaActionKeys {
  FETCH_SAVE_IMOBILIARIA_STARTED = "[SAVE_IMOBILIARIA] FETCH_SAVE_IMOBILIARIA_STARTED",
  FETCH_SAVE_IMOBILIARIA_SUCCEEDED = "[SAVE_IMOBILIARIA] FETCH_SAVE_IMOBILIARIA_SUCCEEDED",
  FETCH_SAVE_IMOBILIARIA_FAILED = "[SAVE_IMOBILIARIA] FETCH_SAVE_IMOBILIARIA_FAILED",
}

export const SaveImobiliariaActions = {
  fetchSaveImobiliaria: (data: INewImobiliaria): fetchSaveImobiliariaAction =>
    createAction(
      SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_STARTED,
      data
    ),
  fetchSaveImobiliariaSucceeded: (
    data: IResponseAPI<boolean>
  ): fetchSaveImobiliariaSuccededAction =>
    createAction(
      SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_SUCCEEDED,
      data
    ),
  fetchSaveImobiliariaFailed: (
    error: IResponseErrorAPI
  ): fetchSaveImobiliariaFailedAction =>
    createAction(
      SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_FAILED,
      error
    ),
};

export type SaveImobiliariaActionUnion = ActionsUnion<
  typeof SaveImobiliariaActions
>;

export type fetchSaveImobiliariaAction = Action<
  SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_STARTED,
  INewImobiliaria
>;
export type fetchSaveImobiliariaSuccededAction = Action<
  SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_SUCCEEDED,
  IResponseAPI<boolean>
>;
export type fetchSaveImobiliariaFailedAction = Action<
  SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_FAILED,
  IResponseErrorAPI
>;
