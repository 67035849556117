import React, { FC, Fragment } from 'react';
import styles from './Infraestrutura.module.scss';
import iconRedeDeAgua from './../../../static/images/IconRedeAgua.svg'
import iconRedeDeEnergia from './../../../static/images/IconRedeEnergia.svg';
import iconRedeDeEsgoto from './../../../static/images/IconRedeEsgoto.svg';
import iconRedeDeRuasPavimentadas from './../../../static/images/IconRuasPavimentadas.svg';
import iconAreaPreservada from './../../../static/images/IconAreaPreservada.svg';

export interface IIfraestrutura {
    setRef: (value: any) => void;
}

const Infraestrutura: FC<IIfraestrutura> = ({ setRef }) => {
    const items = [
        {
            icon: iconRedeDeAgua,
            text: 'Rede de água'
        },
        {
            icon: iconRedeDeEnergia,
            text: 'Rede de Energia'
        },
        {
            icon: iconRedeDeEsgoto,
            text: 'Rede de Esgoto'
        },
        {
            icon: iconRedeDeRuasPavimentadas,
            text: 'Ruas pavimentadas'
        },
        {
            icon: iconAreaPreservada,
            text: 'Área Verde Preservada'
        }
    ];

    return (
        <Fragment>
            <div className={styles['Infraestrutura']} ref={setRef} >
                <h4>Infraestrutura</h4>
                <div className={styles['items']}>
                    {items.map((x, idx) => (
                        <div key={`${x.text.trim()}-Item-${idx}`} className={styles['item']}>
                            <img alt={`${x.text.trim()}-Item-${idx}`} src={x.icon} />
                            <p>{x.text}</p>
                        </div>
                    ))}
                </div>
                <div className={styles['images']}>
                    <div className={styles['img1']} />
                    <div className={styles['img2']} />
                </div>
            </div>
        </Fragment>
    )
};

export default Infraestrutura;