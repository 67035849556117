import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../Model/IResponseAPI';
import { IQuadraUpdate } from '../../Model/IQuadraUpdate';

export enum QuadraUpdateActionKeys {
  FETCH_QUADRA_UPDATE_STARTED = '[QUADRA_UPDATE] FETCH_QUADRA_UPDATE_STARTED',
  FETCH_QUADRA_UPDATE_SUCCEEDED = '[QUADRA_UPDATE] FETCH_QUADRA_UPDATE_SUCCEEDED',
  FETCH_QUADRA_UPDATE_FAILED = '[QUADRA_UPDATE] FETCH_QUADRA_UPDATE_FAILED'
}

export const QuadraUpdateActions = {
  fetchQuadraUpdate: (data: IQuadraUpdate): fetchQuadraUpdateAction =>
    createAction(QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_STARTED, data),
  fetchQuadraUpdateSucceeded: (data: IResponseAPI<boolean>): fetchQuadraUpdateSuccededAction =>
    createAction(QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_SUCCEEDED, data),
  fetchQuadraUpdateFailed: (error: IResponseErrorAPI): fetchQuadraUpdateFailedAction =>
    createAction(QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_FAILED, error)
};

export type QuadraUpdateActionUnion = ActionsUnion<typeof QuadraUpdateActions>;

export type fetchQuadraUpdateAction = Action<
  QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_STARTED,
  IQuadraUpdate
>;
export type fetchQuadraUpdateSuccededAction = Action<
  QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_SUCCEEDED,
  IResponseAPI<boolean>
>;
export type fetchQuadraUpdateFailedAction = Action<
  QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_FAILED,
  IResponseErrorAPI
>;
