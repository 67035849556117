import React, { Component } from 'react';
import { UserPopup } from './UserPopup/UserPopup';
import styles from './UserInfo.module.scss';
import * as Icon from 'react-feather';
import { securityService } from '../../../../Utils/SecurityService';
import { store } from '../../../../Store';
import { LogoutActions } from '../../../../Store/Usuario/Logout/actions';

export interface IUserInfo {
}
interface IUserInfoState {
  open: boolean;
}

const popupItems = [
  // { name: 'Meu Perfil' },
  // { name: 'Sobre' },
  {
    name: 'Sair',
    noBorder: true,
    onClick: () => store.dispatch(LogoutActions.fetchLogout())
  }
];

class UserInfo extends Component<IUserInfo, IUserInfoState> {
  state = { open: false };
  private avatar = 'https://via.placeholder.com/72';

  tooglePopup = () => {
    this.setState((state) => {
      return {
        open: !state.open
      };
    });
  };

  render() {
    const session = securityService.getSession();

    return (
      <div className={styles['UserInfo']} onClick={this.tooglePopup}>
        <div className={styles['container']}>
          <img className={styles['avatar']} src={this.avatar} alt="" />
          <p className={styles['text']}>
            {(session && session.nome) || 'Não encontrado'}
          </p>
          {this.state.open ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
        </div>

        {this.state.open ? <UserPopup items={popupItems} /> : null}
      </div>
    );
  }
}

export default UserInfo;
