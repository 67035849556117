import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { ISolicitarLote } from '../../Model/ISolicitarLote';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../Model/IResponseAPI';

export enum SolicitarLoteActionKeys {
  FETCH_SOLICITA_LOTE_STARTED = '[SOLICITA_LOTE] FETCH_SOLICITA_LOTE_STARTED',
  FETCH_SOLICITA_LOTE_SUCCEEDED = '[SOLICITA_LOTE] FETCH_SOLICITA_LOTE_SUCCEEDED',
  FETCH_SOLICITA_LOTE_FAILED = '[SOLICITA_LOTE] FETCH_SOLICITA_LOTE_FAILED'
}

export const SolicitarLoteActions = {
  fetchSolicitarLote: (data: ISolicitarLote): fetchSolicitarLoteAction =>
    createAction(SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_STARTED, data),
  fetchSolicitarLoteSucceeded: (data: IResponseAPI<boolean>): fetchSolicitarLoteSuccededAction =>
    createAction(SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_SUCCEEDED, data),
  fetchSolicitarLoteFailed: (error: IResponseErrorAPI): fetchSolicitarLoteFailedAction =>
    createAction(SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_FAILED, error)
};

export type SolicitarLoteActionUnion = ActionsUnion<typeof SolicitarLoteActions>;

export type fetchSolicitarLoteAction = Action<
  SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_STARTED,
  ISolicitarLote
>;
export type fetchSolicitarLoteSuccededAction = Action<
  SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_SUCCEEDED,
  IResponseAPI<boolean>
>;
export type fetchSolicitarLoteFailedAction = Action<
  SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_FAILED,
  IResponseErrorAPI
>;
