import { ILoginResult } from "../Model/ILoginResult";
import jwt_decode from 'jwt-decode';

const SESSION_KEY = 'state_cidade_jardim';

export interface ISession {
    token: string;
    nome: string;
}

export class SecurityService {
    /**
     * Returns the bearer auth token.
     */
    getToken(): string | undefined {
        const local = this.getSession();
        return local ? local.token : undefined;
    }

    /**
     * Indicates if the user is authenticated.
     */
    isAuthenticated() {
        return !!this.getToken();
    }

    /**
     * Get Role of User
     */
    getUserRole(): string {
        if (this.isAuthenticated()) {
            const token = this.getToken() as string;
            const token_decoded = jwt_decode(token) as any;
            return (token_decoded && token_decoded.role) || '';
        }

        return '';
    }

    getSession = (): ISession | undefined => {
        if (window.localStorage.hasOwnProperty(SESSION_KEY)) {
            const session = window.localStorage.getItem(SESSION_KEY);
            if (session !== null) {
                return JSON.parse(session);
            }
        }

        return undefined;
    };

    saveSession = (values: ILoginResult): void => {
        localStorage.setItem(SESSION_KEY, JSON.stringify({
            token: values.token,
            expiresAt: values.expiresAt,
            nome: values.nome
        }));
    }
}

export const securityService = new SecurityService();
