import { QuadraInfoActionUnion, QuadraInfoActionKeys } from './action';
import { IQuadraInfoState } from './IQuadraInfoState';

const initialState: IQuadraInfoState = {
  data: {
    data: {
      quadra: '',
      lotesQuadra: []
    },
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const quadraInfo = (state = initialState, action: QuadraInfoActionUnion): IQuadraInfoState => {
  switch (action.type) {
    case QuadraInfoActionKeys.FETCH_QUADRA_INFO_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case QuadraInfoActionKeys.FETCH_QUADRA_INFO_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case QuadraInfoActionKeys.FETCH_QUADRA_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default quadraInfo;
