import { ISearch } from '../Model/ISearch';

class ApiBase {
    static createUrl(
        baseUrl: string,
        search?: ISearch
    ): string {
        let url = baseUrl;

        return search ? this.addSearch(url, search) : url;
    }

    static addSearch(url: string, search?: ISearch): string {
        if (!search) {
            return url;
        }

        return this.addSlashDivider(url, search);
    }

    static addSlashDivider(url: string, search: ISearch) {
        url += `/${search.pageSize || 10}`;
        url += `/${search.pageIndex || 0}`;
        url += (search.pesquisa && `/${search.pesquisa}`) || '';
        return url;
    }
}

export default ApiBase;
