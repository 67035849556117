import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import styles from './../../TableComponents.module.scss';

export interface ITableHaderItemComponent {
  title: string;
  conteudo: string;
  xl?: boolean | 1 | 2 | 7 | 6 | 5 | 4 | 3 | 'auto' | 8 | 9 | 10 | 11 | 12 | undefined;
}

const TableHaderItemComponent: FC<ITableHaderItemComponent> = ({
  title,
  conteudo,
  xl
}) => (
  <Grid item xl={xl} className={`${styles['child']}`}>
    <div className={styles['header-icon']}>
      <div className={styles['content']}>
        <h4 className={styles['title']}>{title}</h4>
        <p style={{ color: 'text' }} className={styles['conteudo']}>
          {conteudo}
        </p>
      </div>
    </div>
  </Grid>
);

export default TableHaderItemComponent;
