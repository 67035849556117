import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { ILoteamentoDetail } from '../../Model/ILoteamentoDetail';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../Model/IResponseAPI';

export enum QuadrasActionKeys {
  FETCH_QUADRAS_STARTED = '[QUADRAS] FETCH_QUADRAS_STARTED',
  FETCH_QUADRAS_SUCCEEDED = '[QUADRAS] FETCH_QUADRAS_SUCCEEDED',
  FETCH_QUADRAS_FAILED = '[QUADRAS] FETCH_QUADRAS_FAILED'
}

export const QuadrasActions = {
  fetchQuadras: (): fetchQuadrasAction =>
    createAction(QuadrasActionKeys.FETCH_QUADRAS_STARTED),
  fetchQuadrasSucceeded: (data: IResponseAPI<ILoteamentoDetail[]>): fetchQuadrasSuccededAction =>
    createAction(QuadrasActionKeys.FETCH_QUADRAS_SUCCEEDED, data),
  fetchQuadrasFailed: (error: IResponseErrorAPI): fetchQuadrasFailedAction =>
    createAction(QuadrasActionKeys.FETCH_QUADRAS_FAILED, error)
};

export type QuadrasActionUnion = ActionsUnion<typeof QuadrasActions>;

export type fetchQuadrasAction = Action<
  QuadrasActionKeys.FETCH_QUADRAS_STARTED
>;
export type fetchQuadrasSuccededAction = Action<
  QuadrasActionKeys.FETCH_QUADRAS_SUCCEEDED,
  IResponseAPI<ILoteamentoDetail[]>
>;
export type fetchQuadrasFailedAction = Action<
  QuadrasActionKeys.FETCH_QUADRAS_FAILED,
  IResponseErrorAPI
>;
