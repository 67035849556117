import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { IImobiliaria } from '../../Model/IImobiliaria';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IAPIListResponse } from '../../Model/IAPIListResponse';
import { ISearch } from '../../Model/ISearch';

export enum ImobiliariaActionKeys {
  FETCH_IMOBILIARIA_STARTED = '[IMOBILIARIA] FETCH_IMOBILIARIA_STARTED',
  FETCH_IMOBILIARIA_SUCCEEDED = '[IMOBILIARIA] FETCH_IMOBILIARIA_SUCCEEDED',
  FETCH_IMOBILIARIA_FAILED = '[IMOBILIARIA] FETCH_IMOBILIARIA_FAILED'
}

export const ImobiliariaActions = {
  fetchImobiliaria: (data: ISearch): fetchImobiliariaAction =>
    createAction(ImobiliariaActionKeys.FETCH_IMOBILIARIA_STARTED, data),
  fetchImobiliariaSucceeded: (data: IAPIListResponse<IImobiliaria[]>): fetchImobiliariaSuccededAction =>
    createAction(ImobiliariaActionKeys.FETCH_IMOBILIARIA_SUCCEEDED, data),
  fetchImobiliariaFailed: (error: IResponseErrorAPI): fetchImobiliariaFailedAction =>
    createAction(ImobiliariaActionKeys.FETCH_IMOBILIARIA_FAILED, error)
};

export type ImobiliariaActionUnion = ActionsUnion<typeof ImobiliariaActions>;

export type fetchImobiliariaAction = Action<
  ImobiliariaActionKeys.FETCH_IMOBILIARIA_STARTED,
  ISearch
>;
export type fetchImobiliariaSuccededAction = Action<
  ImobiliariaActionKeys.FETCH_IMOBILIARIA_SUCCEEDED,
  IAPIListResponse<IImobiliaria[]>
>;
export type fetchImobiliariaFailedAction = Action<
  ImobiliariaActionKeys.FETCH_IMOBILIARIA_FAILED,
  IResponseErrorAPI
>;
