import React, { FC } from 'react';
import styles from './../TableComponents.module.scss';
import { Grid } from '@material-ui/core';
import TableHaderItemComponent, {
  ITableHaderItemComponent
} from './Item/TableHaderItemComponent';
import GridXL from '../../../../Utils/GridXL';

export interface ITableHaderComponent {
  headerName: string;
  itemsRight?: ITableHaderItemComponent[];
}

const TableHaderComponent: FC<ITableHaderComponent> = ({
  headerName,
  itemsRight
}) => {
  return (
    <Grid container xl={12} className={`${styles['header']}`}>
      <Grid item xl={6} className={styles['title']}>
        <p className={`${styles['text']}`}>{headerName}</p>
      </Grid>
      <Grid container xl={6} className={styles['items']}>
        {itemsRight &&
          itemsRight.map((x) => (
            <TableHaderItemComponent {...x} xl={GridXL.countXL(itemsRight.length)} />
          ))}
      </Grid>
    </Grid>
  );
};

export default TableHaderComponent;
