import React, { FC, ReactNode } from 'react';
import styles from './FormComponent.module.scss';
import { Form, FormRenderProps } from 'react-final-form';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import { Decorator, Mutator } from 'final-form';

export interface IForm {
  onSubmit: (values: any) => void;
  initialValues?: Object;
  schema: yup.ObjectSchema;
  decorators?: Decorator[];
  render: (props: FormRenderProps<Object>) => ReactNode;
  className?: any;
  mutators?: { [key: string]: Mutator };
}

const FormComponent: FC<IForm> = ({
  onSubmit,
  initialValues,
  schema,
  render,
  decorators,
  mutators,
  className
}) => {
  const validateForm = async (values: any) => {
    try {
      return (await schema.validate(values, { abortEarly: false })) === null;
    } catch (err) {
      const errors = err.inner.reduce(
        (formError: any, innerError: any) => ({
          ...formError,
          [innerError.path]: innerError.message
        }),
        {}
      );

      return errors;
    }
  };

  return (
    <div className={`${styles['Form']} ${className}`}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
          ...mutators,
          addValue: ([name, val], state, { changeValue }) => {
            changeValue(state, name, value => value = val);
          }
        }}
        decorators={[...(decorators || [])]}
        validate={validateForm}
        render={render}
      />
    </div>
  );
};

export default FormComponent;
