import React, { Component, Fragment } from "react";
import styles from "./Home.module.scss";
import { Infraestrutura } from "../../Components/Site/Infraestrutura";
import { Noticias } from "../../Components/Site/Noticias";
import { Localizacao } from "../../Components/Site/Localizacao";
import { Imobiliarias } from "../../Components/Site/Imobiliarias";
import { Header } from "../../Components/Site/Header";
import { Loteamento } from "../../Components/Site/Loteamento";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { INoticiaState } from "../../Store/Noticia/INoticiaState";
import { monthNames } from "../../Utils/Month";
import { IImobiliariaState } from "../../Store/Imobiliaria/IImobiliariaState";
import { RouteComponentProps } from "react-router";
import { isBrowser } from "react-device-detect";

let loteamentoRef: any;
let infraRef: any;
let noticiasRef: any;
let localizacaoRef: any;
let contatoRef: any;

const scrollToContent = (content: any) => {
  switch (content) {
    case 2:
      infraRef.scrollIntoView({ behavior: "smooth", block: "end" });
      break;
    case 3:
      noticiasRef.scrollIntoView({ behavior: "smooth", block: "end" });
      break;
    case 4:
      localizacaoRef.scrollIntoView({ behavior: "smooth", block: "end" });
      break;
    case 5:
      contatoRef.scrollIntoView({ behavior: "smooth", block: "end" });
      break;
    default:
      loteamentoRef.scrollIntoView({ behavior: "smooth", block: "end" });
      break;
  }
};

export const menuItems = [
  {
    title: "Loteamento",
    onClick: () => scrollToContent(1),
  },
  {
    title: "Infraestrutura",
    onClick: () => scrollToContent(2),
  },
  {
    title: "Localização",
    onClick: () => scrollToContent(4),
  },
  {
    title: "Contato",
    onClick: () => window.scrollTo(0, document.body.scrollHeight),
  },
];

interface IParamsHome {
  quadra: string;
  lote: string;
}

export interface IHome extends RouteComponentProps<IParamsHome> {
  noticias: INoticiaState;
  imobiliarias: IImobiliariaState;
}

class Home extends Component<IHome> {
  state = {
    isMobile: false,
  };

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <div className={styles.container}>
          {isBrowser && [
            <Header
              title="EM BREVE"
              subTitle="Faça seu cadastro clicando no lote desejado"
              hasDownIcon
            />,
            <Loteamento setRef={(val) => (loteamentoRef = val)} />,
          ]}
          <Infraestrutura setRef={(val) => (infraRef = val)} />
          {this.props.noticias.data.data.data.length > 0 && (
            <Noticias
              setRef={(val) => (noticiasRef = val)}
              items={this.props.noticias.data.data.data.map((b) => {
                return {
                  title: b.title,
                  descricao: b.descricao,
                  image: `${process.env.REACT_APP_API_URL}/Files/images/${b.fotoName}`,
                  mes: monthNames[new Date(b.data).getMonth()],
                  dia: new Date(b.data).getDate(),
                };
              })}
            />
          )}
          {this.props.imobiliarias.data.data.data.length > 0 && (
            <Imobiliarias
              items={this.props.imobiliarias.data.data.data.map((b) => {
                return {
                  nome: b.nome,
                  image: `${process.env.REACT_APP_API_URL}/Files/images/${b.fotoName}`,
                };
              })}
            />
          )}
          <Localizacao setRef={(val) => (localizacaoRef = val)} />
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state: any) => {
  const {
    noticias,
    imobiliaria: { all },
  } = state;
  console.log(noticias);
  return {
    imobiliarias: all,
    noticias,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
