import { call, put, all } from 'redux-saga/effects';
import { fetchSaveUserAction, SaveUserActions } from './action';
import UsuarioAPI from './../../../Data/UsuarioAPI';
import { securityService } from '../../../Utils/SecurityService';
import { history } from '../../..';

export function* fetchSaveUser(action: fetchSaveUserAction) {
  try {
    const data = yield call(UsuarioAPI.fetchSaveUser, action.payload);

    yield all([
      securityService.saveSession(data.data.data),
      put(SaveUserActions.fetchSaveUserSucceeded(data.data)),
      history.push('/painel')
    ])
  } catch (e) {
    yield put(SaveUserActions.fetchSaveUserFailed(e.message));
  }
}
