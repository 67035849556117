import axios, { AxiosInstance } from 'axios';
import { IncomingHttpHeaders } from 'http';
import { securityService } from './SecurityService';

export class Http {
  static async axios(): Promise<AxiosInstance> {
    const headers: IncomingHttpHeaders = {
      'content-type': 'application/json'
    };

    if (securityService.isAuthenticated()) {
      headers['authorization'] = `Bearer ${securityService.getToken()}`;
    }

    const instance = axios.create({
      headers,
      timeout: 120 * 1000,
      baseURL: process.env.REACT_APP_API_URL
    });

    instance.interceptors.response.use(undefined, (err) => {
      const res = err.response;

      console.log(res);
      return Promise.reject(err);
    });

    return instance;
    // TODO: Add caching to not create every time, only if key is added/missing
  }
}
