import { UpdateLoteActionUnion, UpdateLoteActionKeys } from './action';
import { IUpdateLoteState } from './IUpdateLoteState';

const initialState: IUpdateLoteState = {
  data: {
    data: false,
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const updateLoteReducer = (state = initialState, action: UpdateLoteActionUnion): IUpdateLoteState => {
  switch (action.type) {
    case UpdateLoteActionKeys.FETCH_UPDATE_LOTE_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case UpdateLoteActionKeys.FETCH_UPDATE_LOTE_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case UpdateLoteActionKeys.FETCH_UPDATE_LOTE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default updateLoteReducer;
