import { createAction, Action, ActionsUnion } from '../actionHelpers';
import { INoticia } from '../../Model/INoticia';
import { IResponseErrorAPI } from '../../Model/IResponseErrorAPI';
import { IAPIListResponse } from '../../Model/IAPIListResponse';
import { ISearch } from '../../Model/ISearch';

export enum NoticiaActionKeys {
  FETCH_NOTICIA_STARTED = '[NOTICIA] FETCH_NOTICIA_STARTED',
  FETCH_NOTICIA_SUCCEEDED = '[NOTICIA] FETCH_NOTICIA_SUCCEEDED',
  FETCH_NOTICIA_FAILED = '[NOTICIA] FETCH_NOTICIA_FAILED'
}

export const NoticiaActions = {
  fetchNoticia: (data: ISearch): fetchNoticiaAction =>
    createAction(NoticiaActionKeys.FETCH_NOTICIA_STARTED, data),
  fetchNoticiaSucceeded: (data: IAPIListResponse<INoticia[]>): fetchNoticiaSuccededAction =>
    createAction(NoticiaActionKeys.FETCH_NOTICIA_SUCCEEDED, data),
  fetchNoticiaFailed: (error: IResponseErrorAPI): fetchNoticiaFailedAction =>
    createAction(NoticiaActionKeys.FETCH_NOTICIA_FAILED, error)
};

export type NoticiaActionUnion = ActionsUnion<typeof NoticiaActions>;

export type fetchNoticiaAction = Action<
  NoticiaActionKeys.FETCH_NOTICIA_STARTED,
  ISearch
>;
export type fetchNoticiaSuccededAction = Action<
  NoticiaActionKeys.FETCH_NOTICIA_SUCCEEDED,
  IAPIListResponse<INoticia[]>
>;
export type fetchNoticiaFailedAction = Action<
  NoticiaActionKeys.FETCH_NOTICIA_FAILED,
  IResponseErrorAPI
>;
