import React, { FC } from 'react';
import styles from './UserPopup.module.scss';

export interface IUserPopup {
  items: {
    name: string;
    noBorder?: boolean;
    onClick?: () => void;
  }[];
}

export const UserPopup: FC<IUserPopup> = (props) => (
  <div className={styles['UserPopup']}>
    {props.items.map((x) => (
      <div key={x.name.trim()} onClick={x.onClick} className={`${styles['btn']} ${x.noBorder ? styles['noBorder'] : ''}`}>
        <p className={styles['text']}>{x.name}</p>
      </div>
    ))}

    <i className={styles['arrow-up']} />
  </div>
);
