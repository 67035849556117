import { all, takeLatest } from "redux-saga/effects";
import { QuadraInfoActionKeys } from "./QuadraInfo/action";
import { fetchLoteamento } from "./QuadraInfo/sagas";
import { NoticiaActionKeys } from "./Noticia/action";
import { fetchNoticias } from "./Noticia/sagas";
import { SolicitarLoteActionKeys } from "./SolicitaLote/action";
import { fetchSolicitarLote } from "./SolicitaLote/sagas";
import { SaveNoticiaActionKeys } from "./Noticia/Save/action";
import { fetchSaveNoticia } from "./Noticia/Save/sagas";
import { LoginActionKeys } from "./Usuario/Login/action";
import { fetchLogin } from "./Usuario/Login/sagas";
import { AllUsersActionKeys } from "./Usuario/AllUsers/actions";
import { fetchAllUsers } from "./Usuario/AllUsers/sagas";
import { LogoutActionKeys } from "./Usuario/Logout/actions";
import { fetchLogout } from "./Usuario/Logout/sagas";
import { QuadrasActionKeys } from "./Quadras/action";
import { fetchQuadras } from "./Quadras/sagas";
import { UpdateLoteActionKeys } from "./UpdateLoteDisponivel/action";
import { fetchUpdateLote } from "./UpdateLoteDisponivel/sagas";
import { QuadraUpdateActionKeys } from "./QuadraUpdate/action";
import { fetchQuadraUpdate } from "./QuadraUpdate/sagas";
import { ImobiliariaActionKeys } from "./Imobiliaria/action";
import { fetchImobiliarias } from "./Imobiliaria/sagas";
import { SaveImobiliariaActionKeys } from "./Imobiliaria/Save/action";
import { fetchSaveImobiliaria } from "./Imobiliaria/Save/sagas";
import { SaveUserActionKeys } from "./Usuario/SaveUser/action";
import { fetchSaveUser } from "./Usuario/SaveUser/sagas";

export function* rootSaga() {
  yield all([
    takeLatest(QuadraInfoActionKeys.FETCH_QUADRA_INFO_STARTED, fetchLoteamento),
    takeLatest(NoticiaActionKeys.FETCH_NOTICIA_STARTED, fetchNoticias),
    takeLatest(
      SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_STARTED,
      fetchSolicitarLote
    ),
    takeLatest(
      SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_STARTED,
      fetchSaveNoticia
    ),
    takeLatest(LoginActionKeys.FETCH_LOGIN_STARTED, fetchLogin),
    takeLatest(AllUsersActionKeys.FETCH_ALL_USERS_STARTED, fetchAllUsers),
    takeLatest(LogoutActionKeys.FETCH_LOGOUT_STARTED, fetchLogout),
    takeLatest(QuadrasActionKeys.FETCH_QUADRAS_STARTED, fetchQuadras),
    takeLatest(UpdateLoteActionKeys.FETCH_UPDATE_LOTE_STARTED, fetchUpdateLote),
    takeLatest(
      QuadraUpdateActionKeys.FETCH_QUADRA_UPDATE_STARTED,
      fetchQuadraUpdate
    ),
    takeLatest(
      ImobiliariaActionKeys.FETCH_IMOBILIARIA_STARTED,
      fetchImobiliarias
    ),
    takeLatest(
      SaveImobiliariaActionKeys.FETCH_SAVE_IMOBILIARIA_STARTED,
      fetchSaveImobiliaria
    ),
    takeLatest(SaveUserActionKeys.FETCH_SAVE_USER_STARTED, fetchSaveUser),
  ]);
}
