import React, { Component } from "react";
import styles from "./Noticias.module.scss";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { INoticiaState } from "../../../Store/Noticia/INoticiaState";
import { ISaveNoticiaState } from "../../../Store/Noticia/Save/ISaveNoticiaState";
import { ISearch } from "../../../Model/ISearch";
import { THeadBuilder } from "../../../Components/UI/Table/Builder/THeadBuilder";
import { ITableData } from "../../../Components/UI/Table/Interfaces/ITableData";
import { Loading } from "../../../Components/UI/Loading/Loading";
import { Button } from "../../../Components/UI/Button";
import { NoData } from "../../../Components/UI/NoData/NoData";
import TableComponent from "../../../Components/UI/Table/TableComponent";
import FormComponent from "../../../Components/UI/Form/FormComponent";
import { schema } from "./Noticias.schema";
import { Grid } from "@material-ui/core";
import { TextInput } from "../../../Components/UI/Form/Inputs/TextInput/TextInput";
import { NoticiaActions } from "../../../Store/Noticia/action";
import { SaveNoticiaActions } from "../../../Store/Noticia/Save/action";
import { INewNoticia } from "../../../Model/INewNoticia";

export interface INoticias {
  isLoading: boolean;
  noticias: INoticiaState;
  save: ISaveNoticiaState;
  fetchSalvarNoticia: (data: INewNoticia) => void;
  fetchNoticias: (data: ISearch) => void;
  history: History;
}

class Noticias extends Component<INoticias> {
  componentDidMount() {
    this.props.fetchNoticias({ pageSize: 10 });
  }

  state = {
    registering: false,
    atualId: undefined,
    atualItem: undefined,
    form: {
      id: undefined,
      title: "",
      descricao: "",
      data: new Date(),
      foto: "",
      imageVal: "",
    },
  };

  configTable = (): ITableData => {
    return {
      className: styles["bodyTable"],
      keyColumnName: "id",
      pageSize: this.props.noticias.data.data.pageSize,
      pageIndex: this.props.noticias.data.data.pageIndex,
      total: this.props.noticias.data.data.totalResult,
      HeadData: new THeadBuilder()
        .addCommunHead("title", "Título")
        .addCommunHead("descricao", "Descrição")
        .addCommunHead("data", "Data")
        .build(),
      items: this.props.noticias.data.data.data,
    };
  };

  openRegisterOrEdit = (id?: string) => {
    const { data } = this.props.noticias.data.data;
    const itemIndex = data.findIndex((b) => b.id === id);
    if (itemIndex !== -1) {
      this.setState({
        atualItem: {
          id: data[itemIndex].id,
          title: data[itemIndex].title,
          descricao: data[itemIndex].descricao,
          data: data[itemIndex].data,
        },
        atualId: id,
        registering: true,
      });
    } else {
      this.setState({
        atualId: undefined,
        atualItem: undefined,
        registering: true,
      });
    }
  };

  resetRegister = () => {
    this.setState({
      atualId: undefined,
      atualItem: undefined,
      registering: false,
    });
  };

  render() {
    const initialValues = () => {
      if (this.state.atualItem) {
        return this.state.atualItem;
      } else {
        return this.state.form;
      }
    };
    return [
      <div className={styles["Noticias"]}>
        <Loading isLoading={this.props.isLoading}>
          {!this.state.registering && (
            <div className={styles["nova-raca"]}>
              <Button
                className={styles["button"]}
                size="small"
                variant="contained"
                onClick={() => this.openRegisterOrEdit()}
              >
                Nova Noticia
              </Button>
            </div>
          )}
          <div className={styles["content"]}>
            {this.props.noticias.data.data.data.length === 0 ? (
              <NoData />
            ) : (
              <div
                className={styles["table"]}
                style={{
                  width: this.state.registering ? "auto" : "100%",
                }}
              >
                <TableComponent {...this.configTable()} />
              </div>
            )}
            {this.state.registering && (
              <div className={styles["register-area"]}>
                <div className={styles["close-register"]}>
                  <Button onClick={() => this.resetRegister()}>Fechar</Button>
                </div>
                <FormComponent
                  initialValues={initialValues()}
                  schema={schema}
                  onSubmit={(values) => {
                    this.props.fetchSalvarNoticia({
                      ...values,
                      foto: values.imageVal,
                    });
                    if (this.state.atualId) {
                      this.resetRegister();
                    }
                  }}
                  render={({
                    values,
                    form: {
                      mutators: { addValue },
                    },
                    handleSubmit,
                  }) => {
                    return (
                      <div className={styles["form"]}>
                        {!this.state.atualId && (
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextInput
                                name="foto"
                                type="file"
                                onChangeVal={(value) => {
                                  addValue("imageVal", value.target.files[0]);
                                }}
                                placeHolder="Informe a Imagem"
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextInput
                              name="title"
                              placeHolder="Informe o titulo da Noticia"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextInput
                              name="descricao"
                              type="textarea"
                              placeHolder="Informe a Descrição da Noticia"
                            />
                          </Grid>
                        </Grid>
                        {!this.state.atualId && (
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextInput
                                name="data"
                                type="date"
                                placeHolder="Informe a Data"
                              />
                            </Grid>
                          </Grid>
                        )}
                        <div className={styles["submit-area"]}>
                          <Button
                            className={styles["button"]}
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            Salvar
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </Loading>
      </div>,
    ];
  }
}

const mapStateToProps = (state: any) => {
  const { noticias, saveNoticia } = state;
  return {
    noticias,
    save: saveNoticia,
    isLoading: noticias.isLoading || saveNoticia.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchNoticias: NoticiaActions.fetchNoticia,
      fetchSalvarNoticia: SaveNoticiaActions.fetchSaveNoticia,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Noticias);
