import React, { Fragment, Component } from "react";
import styles from "./Loteamento.module.scss";
import { IQuadraInfoState } from "../../../Store/QuadraInfo/IQuadraInfoState";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { QuadraInfoActions } from "../../../Store/QuadraInfo/action";
import FormComponent from "../../UI/Form/FormComponent";
import { schema } from "./Loteamento.schema";
import { TextInput } from "../../UI/Form/Inputs/TextInput/TextInput";
import { SolicitarLoteActions } from "../../../Store/SolicitaLote/action";
import { ISolicitarLote } from "../../../Model/ISolicitarLote";

export interface ILoteamento {
  setRef: (value: any) => void;
  fetchQuadraInfo: (quadra: string) => void;
  fetchSolicitarLote: (data: ISolicitarLote) => void;
  quadraInfo: IQuadraInfoState;
  isLoading: boolean;
}

class Loteamento extends Component<ILoteamento> {
  state = {
    quadra: "",
    containerQuadraOpened: false,
    lote: 0,
    form: {},
  };

  selectQuadra = (quadra: string): void => {
    this.setState({ quadra, containerQuadraOpened: true, lote: 0 });
    this.props.fetchQuadraInfo(quadra);
  };

  selectLote = (lote: number): void => {
    this.setState({ lote: lote });
  };

  sendRegister = (register: any): void => {
    this.props.fetchSolicitarLote({
      nome: register.nome,
      cidade: register.cidade,
      email: register.email,
      contato: register.telefone,
      lote: this.state.lote.toString(),
      quadra: this.state.quadra,
    });
  };

  render() {
    return (
      <Fragment>
        <div className={styles["Loteamento"]} ref={this.props.setRef}>
          <div
            className={`${styles["map"]} ${
              !this.state.containerQuadraOpened && styles["map-left"]
            }`}
          >
            <img
              alt="Map"
              src={require("./../../../static/images/Map.png")}
              useMap="#Map"
            />
            <map name="Map">
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("A")}
                alt="A"
                title="A"
                coords="230,50,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("B")}
                alt="B"
                title="B"
                coords="360,50,50"
                shape="circle"
              />

              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("C")}
                alt="C"
                title="C"
                coords="70,140,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("D")}
                alt="D"
                title="D"
                coords="230,150,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("E")}
                alt="E"
                title="E"
                coords="460,150,50"
                shape="circle"
              />

              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("F")}
                alt="F"
                title="F"
                coords="70,199,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("G")}
                alt="G"
                title="G"
                coords="230,201,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("H")}
                alt="H"
                title="H"
                coords="460,201,50"
                shape="circle"
              />

              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("I")}
                alt="I"
                title="I"
                coords="80,258,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("J")}
                alt="J"
                title="J"
                coords="240,260,50"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("K")}
                alt="K"
                title="K"
                coords="460,260,50"
                shape="circle"
              />

              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("L")}
                alt="L"
                title="L"
                coords="90,350,40"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("M")}
                alt="M"
                title="M"
                coords="230,350,40"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("N")}
                alt="N"
                title="N"
                coords="460,350,40"
                shape="circle"
              />

              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("O")}
                alt="O"
                title="O"
                coords="230,460,20"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("P")}
                alt="P"
                title="P"
                coords="300,460,20"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("Q")}
                alt="Q"
                title="Q"
                coords="370,460,20"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("R")}
                alt="R"
                title="R"
                coords="410,460,20"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("S")}
                alt="S"
                title="S"
                coords="460,460,20"
                shape="circle"
              />
              <area
                className={styles["point"]}
                target=""
                onClick={() => this.selectQuadra("T")}
                alt="T"
                title="T"
                coords="510,460,20"
                shape="circle"
              />
            </map>
          </div>
          <div
            hidden={!this.state.containerQuadraOpened}
            className={styles["container"]}
          >
            <button
              onClick={() =>
                this.setState({ quadra: "", containerQuadraOpened: false })
              }
              className={styles["close-conainter"]}
            >
              X
            </button>
            <div className={styles["item-map"]}>
              {this.state.quadra && (
                <img
                  alt="QuadraMap"
                  src={require(`./../../../static/images/Quadras/${this.state.quadra}.png`)}
                  useMap={`#MapQuadra-${this.state.quadra}`}
                />
              )}
              <map name="MapQuadra-A">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="70,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="150,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="200,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="260,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="320,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="390,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="460,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="510,45,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="570,50,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="140,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="180,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="240,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="300,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="360,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="420,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="480,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="540,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="610,270,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-B">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="70,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="130,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="180,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="240,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="305,62,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="350,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="410,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="470,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="520,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="590,60,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="70,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="140,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="180,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="240,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="300,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="360,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="420,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="480,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="540,280,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="610,260,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-C">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="150,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="220,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="260,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="300,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="340,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="420,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="460,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="500,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="540,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="640,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="640,65,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="640,100,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="640,140,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="640,170,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="640,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="640,240,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="110,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="150,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="220,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="260,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="300,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="340,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="420,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="460,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="500,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="540,240,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-D">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="130,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="170,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="210,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="250,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="280,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="340,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="380,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="420,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="460,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="500,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="540,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="580,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="660,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="660,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="660,85,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="660,110,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="660,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="660,160,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="660,190,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="130,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="170,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="210,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="250,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="280,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="340,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="380,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="420,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="460,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="500,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="540,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="580,190,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="40,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="40,85,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="40,110,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="40,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="40,160,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="45,200,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-E">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="150,230,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="190,220,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="240,220,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="275,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="310,180,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="340,160,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="150,415,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="200,410,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="240,410,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="280,410,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,410,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="360,410,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="400,410,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="50,440,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="50,400,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="50,370,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="50,340,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="50,310,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="50,260,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-F">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="60,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="150,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="220,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="260,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="320,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="380,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="420,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="500,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="540,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="640,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="640,65,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="640,100,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="640,140,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="640,170,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="640,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="640,240,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="110,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="150,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="220,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="260,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="340,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="360,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="420,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="490,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="530,270,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-G">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="130,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="170,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="210,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="250,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="280,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="340,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="380,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="420,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="460,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="500,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="540,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="580,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="660,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="660,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="660,85,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="660,110,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="660,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="660,160,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="660,190,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="130,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="170,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="210,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="250,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="280,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="340,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="380,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="420,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="460,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="500,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="540,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="580,190,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="40,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="40,85,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="40,110,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="40,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="40,160,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="45,200,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-H">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="150,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="190,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="240,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="280,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="320,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="360,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="400,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="440,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="480,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="520,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="560,70,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="620,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="630,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="630,100,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="630,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="640,150,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="650,175,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="650,205,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="650,230,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="650,260,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="150,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="190,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="240,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="280,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="320,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="360,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="400,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="440,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="480,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="520,260,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="560,260,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="45,90,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="45,120,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="45,150,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="45,175,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="45,205,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="45,230,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="50,260,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-I">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="60,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="150,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="220,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="260,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="320,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="380,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="440,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="520,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="620,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="640,100,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="640,140,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="640,170,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="640,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="640,240,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="640,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="640,240,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="110,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="180,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="240,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="310,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="370,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="440,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="520,270,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-J">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="130,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="170,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="210,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="250,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="280,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="340,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="380,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="420,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="460,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="500,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="540,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="580,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="660,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="660,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="660,85,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="660,110,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="660,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="660,160,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="660,190,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="130,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="170,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="210,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="250,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="280,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="340,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="380,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="420,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="460,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="500,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="540,190,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="580,190,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="40,60,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="40,85,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="40,110,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="40,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="40,160,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="45,200,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-K">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="150,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="180,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="220,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="250,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="280,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="320,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="360,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="400,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="480,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="520,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="560,40,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="620,40,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="640,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="640,100,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="640,130,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="650,150,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="660,170,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="650,200,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="130,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="160,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="220,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="260,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="290,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="320,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="360,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="400,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="440,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="510,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="540,210,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="580,210,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="45,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="45,70,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="45,90,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="45,120,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="45,150,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="45,175,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="45,205,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-L">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="60,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="150,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="220,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="260,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="290,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="320,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="380,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="420,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="450,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="480,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="520,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="560,55,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="610,55,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="100,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="150,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="210,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="250,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="280,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="310,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="370,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="410,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="440,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="470,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="510,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="550,270,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="600,270,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-M">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="60,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="120,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="140,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="160,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="220,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="240,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="340,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="370,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="420,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="460,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="480,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="520,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="560,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="600,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="620,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="640,50,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(50)}
                  alt="Lote 50"
                  title="Lote 50"
                  coords="40,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(49)}
                  alt="Lote 49"
                  title="Lote 49"
                  coords="60,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(48)}
                  alt="Lote 48"
                  title="Lote 48"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(47)}
                  alt="Lote 47"
                  title="Lote 47"
                  coords="120,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(46)}
                  alt="Lote 46"
                  title="Lote 46"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(45)}
                  alt="Lote 45"
                  title="Lote 45"
                  coords="160,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(44)}
                  alt="Lote 44"
                  title="Lote 44"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(43)}
                  alt="Lote 43"
                  title="Lote 43"
                  coords="220,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(42)}
                  alt="Lote 42"
                  title="Lote 42"
                  coords="240,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(41)}
                  alt="Lote 41"
                  title="Lote 41"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(40)}
                  alt="Lote 40"
                  title="Lote 40"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(39)}
                  alt="Lote 39"
                  title="Lote 39"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="340,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="370,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="420,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="450,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="480,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="520,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="560,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="600,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="620,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="640,200,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-N">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="40,80,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="60,80,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="100,80,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="120,80,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="140,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="160,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="220,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="240,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="310,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="330,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="350,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="460,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="480,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="520,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="560,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="600,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="620,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="650,50,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(52)}
                  alt="Lote 52"
                  title="Lote 52"
                  coords="40,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(51)}
                  alt="Lote 51"
                  title="Lote 51"
                  coords="60,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(50)}
                  alt="Lote 50"
                  title="Lote 50"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(49)}
                  alt="Lote 49"
                  title="Lote 49"
                  coords="120,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(48)}
                  alt="Lote 48"
                  title="Lote 48"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(47)}
                  alt="Lote 47"
                  title="Lote 47"
                  coords="160,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(46)}
                  alt="Lote 46"
                  title="Lote 46"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(45)}
                  alt="Lote 45"
                  title="Lote 45"
                  coords="220,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(44)}
                  alt="Lote 44"
                  title="Lote 44"
                  coords="240,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(43)}
                  alt="Lote 43"
                  title="Lote 43"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(42)}
                  alt="Lote 42"
                  title="Lote 42"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(41)}
                  alt="Lote 41"
                  title="Lote 41"
                  coords="310,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(40)}
                  alt="Lote 40"
                  title="Lote 40"
                  coords="330,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(39)}
                  alt="Lote 39"
                  title="Lote 39"
                  coords="350,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="460,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="480,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="520,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="560,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="600,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="620,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="650,200,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-O">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="40,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="170,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="230,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="340,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="470,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="580,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="620,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="650,180,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="135,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="170,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="230,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="340,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="470,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="580,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="620,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="650,50,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-P">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="40,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="170,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="230,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="340,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="470,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="580,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="620,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="650,180,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="135,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="170,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="230,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="340,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="470,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="580,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="620,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="650,50,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-Q">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="40,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="170,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="230,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="340,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="470,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="580,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="620,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="650,180,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(38)}
                  alt="Lote 38"
                  title="Lote 38"
                  coords="40,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(37)}
                  alt="Lote 37"
                  title="Lote 37"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="135,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="170,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="230,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="340,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="470,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="580,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="620,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="650,50,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-R">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="50,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="170,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="230,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="470,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="570,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="600,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="660,200,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="50,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="140,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="170,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="230,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="470,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="570,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="600,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="640,50,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-S">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="50,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="170,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="230,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="470,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="570,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="600,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="660,200,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="50,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="140,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="170,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="230,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="470,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="570,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="600,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="640,50,20"
                  shape="circle"
                />
              </map>
              <map name="MapQuadra-T">
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(1)}
                  alt="Lote 01"
                  title="Lote 01"
                  coords="50,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(2)}
                  alt="Lote 02"
                  title="Lote 02"
                  coords="100,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(3)}
                  alt="Lote 03"
                  title="Lote 03"
                  coords="140,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(4)}
                  alt="Lote 04"
                  title="Lote 04"
                  coords="170,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(5)}
                  alt="Lote 05"
                  title="Lote 05"
                  coords="200,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(6)}
                  alt="Lote 06"
                  title="Lote 06"
                  coords="230,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(7)}
                  alt="Lote 07"
                  title="Lote 07"
                  coords="260,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(8)}
                  alt="Lote 08"
                  title="Lote 08"
                  coords="290,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(9)}
                  alt="Lote 09"
                  title="Lote 09"
                  coords="320,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(10)}
                  alt="Lote 10"
                  title="Lote 10"
                  coords="380,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(11)}
                  alt="Lote 11"
                  title="Lote 11"
                  coords="400,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(12)}
                  alt="Lote 12"
                  title="Lote 12"
                  coords="440,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(13)}
                  alt="Lote 13"
                  title="Lote 13"
                  coords="470,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(14)}
                  alt="Lote 14"
                  title="Lote 14"
                  coords="500,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(15)}
                  alt="Lote 15"
                  title="Lote 15"
                  coords="540,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(16)}
                  alt="Lote 16"
                  title="Lote 16"
                  coords="570,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(17)}
                  alt="Lote 17"
                  title="Lote 17"
                  coords="600,200,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(18)}
                  alt="Lote 18"
                  title="Lote 18"
                  coords="640,200,20"
                  shape="circle"
                />

                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(36)}
                  alt="Lote 36"
                  title="Lote 36"
                  coords="50,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(35)}
                  alt="Lote 35"
                  title="Lote 35"
                  coords="100,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(34)}
                  alt="Lote 34"
                  title="Lote 34"
                  coords="140,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(33)}
                  alt="Lote 33"
                  title="Lote 33"
                  coords="170,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(32)}
                  alt="Lote 32"
                  title="Lote 32"
                  coords="200,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(31)}
                  alt="Lote 31"
                  title="Lote 31"
                  coords="230,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(30)}
                  alt="Lote 30"
                  title="Lote 30"
                  coords="260,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(29)}
                  alt="Lote 29"
                  title="Lote 29"
                  coords="290,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(28)}
                  alt="Lote 28"
                  title="Lote 28"
                  coords="320,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(27)}
                  alt="Lote 27"
                  title="Lote 27"
                  coords="380,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(26)}
                  alt="Lote 26"
                  title="Lote 26"
                  coords="400,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(25)}
                  alt="Lote 25"
                  title="Lote 25"
                  coords="440,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(24)}
                  alt="Lote 24"
                  title="Lote 24"
                  coords="470,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(23)}
                  alt="Lote 23"
                  title="Lote 23"
                  coords="500,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(22)}
                  alt="Lote 22"
                  title="Lote 22"
                  coords="540,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(21)}
                  alt="Lote 21"
                  title="Lote 21"
                  coords="570,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(20)}
                  alt="Lote 20"
                  title="Lote 20"
                  coords="600,50,20"
                  shape="circle"
                />
                <area
                  className={styles["point"]}
                  target=""
                  onClick={() => this.selectLote(19)}
                  alt="Lote 19"
                  title="Lote 19"
                  coords="640,50,20"
                  shape="circle"
                />
              </map>
            </div>
            <div className={styles["info"]}>
              <h4 className={styles["lote"]}>Selecione o Lote Desejado</h4>
              <div className={styles["group"]}>
                <div className={styles["item"]}>
                  <h5>Quadra:</h5>
                  <h5>{this.state.quadra}</h5>
                </div>
                {this.state.lote !== 0 && (
                  <div className={styles["item"]}>
                    <h5>N° Lote:</h5>
                    <h5>{this.state.lote}</h5>
                  </div>
                )}
              </div>
            </div>
            {this.state.lote !== 0 && (
              <div className={styles["formulario"]}>
                <h5 className={styles["title"]}>
                  CADASTRE-SE (nossos corretores entrarão em contato)
                </h5>
                <FormComponent
                  onSubmit={this.sendRegister}
                  initialValues={this.state.form}
                  schema={schema}
                  className={styles["form"]}
                  render={({ handleSubmit, values, invalid }) => {
                    return (
                      <Fragment>
                        <div className={styles["inputs"]}>
                          <TextInput
                            name="nome"
                            placeHolder="Informe seu nome"
                          />
                          <TextInput
                            name="cidade"
                            placeHolder="Informe sua Cidade"
                          />
                          <TextInput
                            name="email"
                            placeHolder="Informe seu e-mail"
                          />
                          <TextInput
                            name="telefone"
                            placeHolder="Informe seu celular"
                          />
                        </div>
                        <button
                          className={styles["submit"]}
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            handleSubmit();
                          }}
                        >
                          Reservar Lote
                        </button>
                      </Fragment>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchQuadraInfo: QuadraInfoActions.fetchQuadraInfo,
      fetchSolicitarLote: SolicitarLoteActions.fetchSolicitarLote,
    },
    dispatch
  );
};

const mapStateToProps = (state: any) => {
  const { quadraInfo, solicitaLote } = state;
  return {
    quadraInfo,
    solicitaLote,
    isLoading: quadraInfo.isLoading || solicitaLote.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Loteamento);
