import { LoginActionUnion, LoginActionKeys } from './action';
import { ILoginState } from './ILoginState';

const initialState: ILoginState = {
  data: {
    data: {
      expiresAt: new Date(),
      token: '',
      nome: ''
    },
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const loginReducer = (state = initialState, action: LoginActionUnion): ILoginState => {
  switch (action.type) {
    case LoginActionKeys.FETCH_LOGIN_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case LoginActionKeys.FETCH_LOGIN_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case LoginActionKeys.FETCH_LOGIN_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default loginReducer;
