import { createAction, Action, ActionsUnion } from '../../actionHelpers';
import { IResponseErrorAPI } from '../../../Model/IResponseErrorAPI';
import { IResponseAPI } from '../../../Model/IResponseAPI';
import { INewNoticia } from '../../../Model/INewNoticia';

export enum SaveNoticiaActionKeys {
  FETCH_SAVE_NOTICIA_STARTED = '[SAVE_NOTICIA] FETCH_SAVE_NOTICIA_STARTED',
  FETCH_SAVE_NOTICIA_SUCCEEDED = '[SAVE_NOTICIA] FETCH_SAVE_NOTICIA_SUCCEEDED',
  FETCH_SAVE_NOTICIA_FAILED = '[SAVE_NOTICIA] FETCH_SAVE_NOTICIA_FAILED'
}

export const SaveNoticiaActions = {
  fetchSaveNoticia: (data: INewNoticia): fetchSaveNoticiaAction =>
    createAction(SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_STARTED, data),
  fetchSaveNoticiaSucceeded: (data: IResponseAPI<boolean>): fetchSaveNoticiaSuccededAction =>
    createAction(SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_SUCCEEDED, data),
  fetchSaveNoticiaFailed: (error: IResponseErrorAPI): fetchSaveNoticiaFailedAction =>
    createAction(SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_FAILED, error)
};

export type SaveNoticiaActionUnion = ActionsUnion<typeof SaveNoticiaActions>;

export type fetchSaveNoticiaAction = Action<
  SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_STARTED,
  INewNoticia
>;
export type fetchSaveNoticiaSuccededAction = Action<
  SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_SUCCEEDED,
  IResponseAPI<boolean>
>;
export type fetchSaveNoticiaFailedAction = Action<
  SaveNoticiaActionKeys.FETCH_SAVE_NOTICIA_FAILED,
  IResponseErrorAPI
>;
