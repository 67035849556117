import React, { FC, Fragment } from "react";
import styles from "./Imobiliarias.module.scss";

interface IImobiliarias {
  items: {
    image: string;
    nome: string;
  }[];
}

const Imobiliarias: FC<IImobiliarias> = ({ items }) => (
  <Fragment>
    <div className={styles["Imobiliarias"]}>
      <h4>Imobiliárias</h4>
      <div className={styles["items"]}>
        <button className={styles["arrowLeft"]} />
        {items.map((item, idx) => {
          return (
            <button key={`imobiliaria-${idx}`} className={styles["item"]}>
              <img src={item.image} alt={item.nome} />
            </button>
          );
        })}
        <button className={styles["arrowRight"]} />
      </div>
    </div>
  </Fragment>
);

export default Imobiliarias;
