import React, { Component } from 'react';
import styles from './Quadra.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { History } from 'history';
import { IQuadrasState } from '../../../Store/Quadras/IQuadrasState';
import { ITableData } from '../../../Components/UI/Table/Interfaces/ITableData';
import { THeadBuilder } from '../../../Components/UI/Table/Builder/THeadBuilder';
import { Loading } from '../../../Components/UI/Loading/Loading';
import TableComponent from '../../../Components/UI/Table/TableComponent';
import { RouteComponentProps } from 'react-router';
import Money from '../../../Utils/Money';
import { QuadrasActions } from '../../../Store/Quadras/action';
import { Button } from '../../../Components/UI/Button';
import FormComponent from '../../../Components/UI/Form/FormComponent';
import { schema } from './Quadra.schema';
import { IQuadraUpdate } from '../../../Model/IQuadraUpdate';
import { IQuadraUpdateState } from '../../../Store/QuadraUpdate/IQuadraUpdateState';
import { QuadraUpdateActions } from '../../../Store/QuadraUpdate/action';
import { Grid } from '@material-ui/core';
import { TextInput } from '../../../Components/UI/Form/Inputs/TextInput/TextInput';
import { SelectInput } from '../../../Components/UI/Form/Inputs/SelectInput/SelectInput';

export interface IParamsQuadra {
    quadra: string;
}

export interface IQuadra extends RouteComponentProps<IParamsQuadra> {
    isLoading: boolean;
    fetchQuadras: () => void;
    fetchQuadraUpdate: (data: IQuadraUpdate) => void;
    updateQuadra: IQuadraUpdateState;
    quadras: IQuadrasState;
    history: History;
}

class Quadra extends Component<IQuadra> {
    componentDidMount() {
        this.props.fetchQuadras();
    }

    state = {
        registering: false,
        atualId: undefined,
        atualItem: undefined,
        form: {

        }
    }

    configTable = (): ITableData => {
        const { data } = this.props.quadras.data;
        const { quadra } = this.props.match.params;
        const index = data.findIndex(b => b.quadra === quadra);
        return {
            className: styles['bodyTable'],
            clickOnRow: (item) => this.openEdit(item.lote),
            keyColumnName: 'id',
            HeadData: new THeadBuilder()
                .addCommunHead('lote', 'Lote')
                .addCommunHead('disponivel', 'Disponivel')
                .addCommunHead('valor', 'Valor')
                .build(),
            items: (data[index] && data[index].lotesQuadra.map(x => {
                return {
                    lote: x.lote,
                    disponivel: x.disponivel ? 'Sim' : 'Não',
                    valor: Money.format(x.valor)
                }
            })) || []
        };
    };

    openEdit = (lote: number) => {
        const { data } = this.props.quadras.data;
        const { quadra } = this.props.match.params;
        const index = data.findIndex(b => b.quadra === quadra);
        const loteIndex = (data[index] && data[index].lotesQuadra.findIndex(x => x.lote === lote)) || -1;
        if (loteIndex !== -1) {
            this.setState({
                atualItem: {
                    ...data[index].lotesQuadra[loteIndex]
                },
                atualId: data[index].lotesQuadra[loteIndex].id,
                registering: true
            });
        }
    }

    resetEdit = () => {
        this.setState({
            atualId: undefined,
            atualItem: undefined,
            registering: false
        });
    }

    render() {
        const initialValues = () => {
            if (this.state.atualItem) {
                return this.state.atualItem
            } else {
                return this.state.form;
            }
        }
        return (
            <div className={styles['Quadra']}>
                <Loading isLoading={this.props.isLoading}>
                    <div className={styles['content']}>
                        <div className={styles['table']}>
                            <TableComponent {...this.configTable()} />
                        </div>
                        {this.state.registering &&
                            <div className={styles['register-area']}>
                                <div className={styles['close-register']}>
                                    <Button onClick={() => this.resetEdit()} >Fechar</Button>
                                </div>
                                <FormComponent
                                    initialValues={initialValues()}
                                    schema={schema}
                                    onSubmit={(values) => {
                                        this.props.fetchQuadraUpdate({
                                            lotesQuadra:
                                            {
                                                lote: +values.lote,
                                                descricao: values.descricao,
                                                disponivel: values.disponivel === "true",
                                                largura: parseFloat(values.largura),
                                                profundidade: parseFloat(values.profundidade),
                                                valor: parseFloat(values.valor),
                                                total: 0
                                            },
                                            quadra: this.props.match.params.quadra
                                        });
                                        if (this.state.atualId) {
                                            this.resetEdit();
                                        }
                                    }}
                                    render={({ values, form: { mutators: { addValue } }, handleSubmit }) => {
                                        return (
                                            <div className={styles['form']}
                                            >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <TextInput
                                                            name="lote"
                                                            label="Lote"
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <TextInput
                                                            name="valor"
                                                            label="Valor"
                                                            placeHolder="Informe o Valor do lote"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <TextInput
                                                            name="descricao"
                                                            label="Descricão"
                                                            type="textarea"
                                                            placeHolder="Informe a descrição"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <TextInput
                                                            name="largura"
                                                            type="number"
                                                            label="Largura"
                                                            placeHolder="Informe a largura"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <TextInput
                                                            name="profundidade"
                                                            label="Profundidade"
                                                            type="number"
                                                            placeHolder="Informe a profundidade"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <SelectInput
                                                            name="disponivel"
                                                            label="Lote Disponivel"
                                                            itemLabel="label"
                                                            itemValue="value"
                                                            items={[
                                                                {
                                                                    label: 'Sim',
                                                                    value: true
                                                                },
                                                                {
                                                                    label: 'Não',
                                                                    value: false
                                                                }
                                                            ]}
                                                            placeHolder="Informe a disponibilidade"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <div className={styles['submit-area']}>
                                                    <Button
                                                        className={styles['button']}
                                                        variant="contained"
                                                        onClick={handleSubmit}>
                                                        Salvar
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        }
                    </div>
                </Loading>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    const { quadras, updateQuadra } = state;
    return {
        quadras,
        updateQuadra,
        isLoading: quadras.isLoading || updateQuadra.isLoading
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            fetchQuadras: QuadrasActions.fetchQuadras,
            fetchQuadraUpdate: QuadraUpdateActions.fetchQuadraUpdate
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quadra);