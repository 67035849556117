import { fetchUpdateLoteAction } from './action';
import { UpdateLoteActions } from './action';
import { call, put } from 'redux-saga/effects';
import LoteamentoAPI from './../../Data/LoteamentoAPI';

export function* fetchUpdateLote(action: fetchUpdateLoteAction) {
  try {
    const data = yield call(LoteamentoAPI.fetchUpdateLote, action.payload);

    yield put(UpdateLoteActions.fetchUpdateLoteSucceeded(data.data));
  } catch (e) {
    yield put(UpdateLoteActions.fetchUpdateLoteFailed(e.message));
  }
}
