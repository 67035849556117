import { AxiosResponse } from 'axios';
import { Http } from '../Utils/Http';
import { IResponseAPI } from '../Model/IResponseAPI';
import { ILoteamentoDetail } from '../Model/ILoteamentoDetail';
import { IResponseErrorAPI } from '../Model/IResponseErrorAPI';
import { ISolicitarLote } from '../Model/ISolicitarLote';
import { IUpdateLote } from '../Model/IUpdateLote';
import { IQuadraUpdate } from '../Model/IQuadraUpdate';

export interface ILoteamentoDetailAPI {
  fetchLoteamento(quadra: string): Promise<AxiosResponse<IResponseAPI<ILoteamentoDetail> | IResponseErrorAPI>>;
  fetchSolicitaLote(data: ISolicitarLote): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>>;
  fetchUpdateQuadra(data: IQuadraUpdate): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>>;
  fetchQuadras(): Promise<AxiosResponse<IResponseAPI<ILoteamentoDetail[]> | IResponseErrorAPI>>;
  fetchUpdateLote(data: IUpdateLote): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>>;
}

class LoteamentoAPI implements ILoteamentoDetailAPI {
  async fetchUpdateLote(data: IUpdateLote): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>> {
    const { quadra, lote, disponivel } = data;
    const axios = await Http.axios();
    const response = axios.post<IResponseAPI<boolean> | IResponseErrorAPI>(`/api/Loteamento/lote-update/${quadra}/${lote}/${disponivel}`);
    return response;
  }
  async fetchSolicitaLote(data: ISolicitarLote): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    const response = axios.post<IResponseAPI<boolean> | IResponseErrorAPI>(`/api/Loteamento/contato`, data);
    return response;
  }
  async fetchUpdateQuadra(data: IQuadraUpdate): Promise<AxiosResponse<IResponseAPI<boolean> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    const response = axios.post<IResponseAPI<boolean> | IResponseErrorAPI>(`/api/Loteamento/update-info-lote/${data.quadra}`, data.lotesQuadra);
    return response;
  }
  async fetchQuadras(): Promise<AxiosResponse<IResponseAPI<ILoteamentoDetail[]> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    const response = axios.get<IResponseAPI<ILoteamentoDetail[]> | IResponseErrorAPI>(`/api/Loteamento/all-quadras`);
    return response;
  }
  async fetchLoteamento(quadra: string): Promise<AxiosResponse<IResponseAPI<ILoteamentoDetail> | IResponseErrorAPI>> {
    const axios = await Http.axios();
    const response = axios.get<IResponseAPI<ILoteamentoDetail> | IResponseErrorAPI>(`/api/Loteamento/quadra/${quadra}`);
    return response;
  }
}

const instance = new LoteamentoAPI();
export default instance;
