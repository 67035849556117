import React, { FC, Fragment } from "react";
import styles from "./Footer.module.scss";
import logoMrImoveis from "./../../../static/images/mr_imoveis.svg";

const Footer: FC = () => (
  <Fragment>
    <div className={styles["Footer"]}>
      <div className={styles["logoArea"]}>
        <img
          alt="Logo"
          src={require("./../../../static/images/LogoCidadeJardimWhite.svg")}
        />
        <div className={styles["whats"]}>
          <img
            alt="whatsapp"
            className={styles["icon-whats"]}
            src={require("./../../../static/images/Whatsapp.svg")}
          />
          <p>
            <span>(64)</span>3622-4544
          </p>
        </div>
      </div>
      <img alt="Logo" className={styles["logo"]} src={logoMrImoveis} />
      <div className={styles["menuArea"]}>
        <button className={styles["item"]}>> loteamento</button>
        <button className={styles["item"]}>> infraestrutura</button>
        <button className={styles["item"]}>> localização</button>
        <button className={styles["item"]}>> contato</button>
      </div>
      <div className={styles["contatoArea"]}></div>
    </div>
  </Fragment>
);

export default Footer;
