import { fetchNoticiaAction } from './action';
import { NoticiaActions } from './action';
import { call, put } from 'redux-saga/effects';
import NoticiaAPI from './../../Data/NoticiaAPI';

export function* fetchNoticias(action: fetchNoticiaAction) {
  try {
    const data = yield call(NoticiaAPI.fetchMoticias, action.payload);

    yield put(NoticiaActions.fetchNoticiaSucceeded(data.data));
  } catch (e) {
    yield put(NoticiaActions.fetchNoticiaFailed(e.message));
  }
}
