import { SolicitarLoteActionUnion, SolicitarLoteActionKeys } from './action';
import { ISolicitarLoteState } from './ISolicitarLoteState';

const initialState: ISolicitarLoteState = {
  data: {
    data: false,
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const solicitaLoteReducer = (state = initialState, action: SolicitarLoteActionUnion): ISolicitarLoteState => {
  switch (action.type) {
    case SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case SolicitarLoteActionKeys.FETCH_SOLICITA_LOTE_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default solicitaLoteReducer;
