import { createAction, Action, ActionsUnion } from '../../actionHelpers';
import { IAPIListResponse } from '../../../Model/IAPIListResponse';
import { IAPIErrorResponse } from '../../../Model/IAPIErrorResponse';
import { IUserInfo } from '../../../Model/IUserInfo';
import { ISearch } from '../../../Model/ISearch';

export enum AllUsersActionKeys {
    FETCH_ALL_USERS_STARTED = '[ALL_USERS] FETCH_ALL_USERS_STARTED',
    FETCH_ALL_USERS_SUCCEEDED = '[ALL_USERS] FETCH_ALL_USERS_SUCCEEDED',
    FETCH_ALL_USERS_FAILED = '[ALL_USERS] FETCH_ALL_USERS_FAILED'
}

export const AllUsersActions = {
    fetchAllUsers: (data: ISearch): fetchAllUsersAction =>
        createAction(AllUsersActionKeys.FETCH_ALL_USERS_STARTED, data),
    fetchAllUsersSucceeded: (result: IAPIListResponse<IUserInfo[]>): fetchAllUsersSuccededAction =>
        createAction(AllUsersActionKeys.FETCH_ALL_USERS_SUCCEEDED, result),
    fetchAllUsersFailed: (error: IAPIErrorResponse): fetchAllUsersFailedAction =>
        createAction(AllUsersActionKeys.FETCH_ALL_USERS_FAILED, error)
};

export type AllUsersActionUnion = ActionsUnion<typeof AllUsersActions>;

export type fetchAllUsersAction = Action<AllUsersActionKeys.FETCH_ALL_USERS_STARTED, ISearch>;
export type fetchAllUsersSuccededAction = Action<
    AllUsersActionKeys.FETCH_ALL_USERS_SUCCEEDED,
    IAPIListResponse<IUserInfo[]>
>;
export type fetchAllUsersFailedAction = Action<
    AllUsersActionKeys.FETCH_ALL_USERS_FAILED,
    IAPIErrorResponse
>;