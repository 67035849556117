import React, { FC } from 'react';
import { MenuItem, IMenuItem } from './MenuItem/MenuItem';
import UserInfo from './UserInfo/UserInfo';
import { withRouter, RouteComponentProps } from 'react-router';

import styles from './Leftbar.module.scss';

export interface ILeftbar extends RouteComponentProps {
  items: IMenuItem[];
  bottomItems: IMenuItem[];
}

const Leftbar: FC<ILeftbar> = (props) => (
  <div className={styles['Leftbar']}>
    <div>
      {props.items.map((x, idx) => (
        <MenuItem key={`item_${idx}`} {...x} />
      ))}
    </div>
    <div className={styles['bottom-menu']}>
      <UserInfo />
    </div>
  </div>
);

export default withRouter(Leftbar);
