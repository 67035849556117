import React, { FC, Fragment } from 'react';
import styles from './Noticias.module.scss';

export interface INoticia {
    title: string;
    descricao: string;
    image: string;
    mes: string;
    dia: number;
}

export interface INoticias {
    items: INoticia[];
    setRef: (value: any) => void;
}

const Noticias: FC<INoticias> = ({ items, setRef }) => {
    const getNumberOfWords = (desc: string, number: number, split: string = ' ') => {
        const words = desc.split(split);
        return words.slice(0, number).join(' ');
    }
    return (
        <Fragment>
            <div className={styles['Noticias']} ref={setRef}>
                <h4>Notícias</h4>
                <div className={styles['items']}>
                    {items && items.map((item, idx) => (
                        <div key={`${item.title.trim()}-${idx}`} className={styles['item']}>
                            <img alt={item.title} title={item.title} src={item.image} />
                            <div className={styles['text-block']}>
                                <h4 className={styles['month']}>{getNumberOfWords(item.mes, 3, '')}</h4>
                                <h4 className={styles['day']}>{item.dia}</h4>
                            </div>
                            <h5>{item.title}</h5>
                            <p>{getNumberOfWords(item.descricao, 20)}.</p>
                            <button>Ler mais...</button>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
}

export default Noticias;