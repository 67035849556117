import { fetchQuadraUpdateAction } from './action';
import { QuadraUpdateActions } from './action';
import { call, put, all } from 'redux-saga/effects';
import LoteamentoAPI from './../../Data/LoteamentoAPI';
import { QuadrasActions } from '../Quadras/action';

export function* fetchQuadraUpdate(action: fetchQuadraUpdateAction) {
  try {
    const data = yield call(LoteamentoAPI.fetchUpdateQuadra, action.payload);

    yield all([
      put(QuadraUpdateActions.fetchQuadraUpdateSucceeded(data.data)),
      put(QuadrasActions.fetchQuadras())
    ]);
  } catch (e) {
    yield put(QuadraUpdateActions.fetchQuadraUpdateFailed(e.message));
  }
}
