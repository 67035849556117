import { put, all } from 'redux-saga/effects';
import { fetchLogoutAction, LogoutActions } from './actions';
import { history } from '../../..';

export function* fetchLogout(action: fetchLogoutAction) {
    try {
        yield all([
            localStorage.clear(),
            put(LogoutActions.fetchLogoutSucceeded()),
            history.push("/home")
        ]);

    } catch (e) {
        yield put(LogoutActions.fetchLogoutFailed());
    }
}
