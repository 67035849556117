import React, { Component } from "react";
import styles from "./Painel.module.scss";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { QuadrasActions } from "../../Store/Quadras/action";
import { THeadBuilder } from "../../Components/UI/Table/Builder/THeadBuilder";
import { ITableData } from "../../Components/UI/Table/Interfaces/ITableData";
import { IQuadrasState } from "../../Store/Quadras/IQuadrasState";
import { History } from "history";

export interface IPainel {
  fetchQuadras: () => void;
  isLoading: boolean;
  quadras: IQuadrasState;
  history: History;
}

class Painel extends Component<IPainel> {
  componentDidMount() {
    this.props.fetchQuadras();
  }

  configTable = (): ITableData => {
    return {
      className: styles["bodyTable"],
      clickOnRow: (item) =>
        this.props.history.push(`/painel/quadra/${item.quadra}`),
      keyColumnName: "id",
      HeadData: new THeadBuilder()
        .addCommunHead("quadra", "Quadra")
        .addCommunHead("total", "Total de Lotes")
        .addCommunHead("qtdDisponivel", "Lotes Disponiveis")
        .build(),
      items: this.props.quadras.data.data.map((b, idx) => {
        return {
          quadra: b.quadra,
          total: b.lotesQuadra.length,
          qtdDisponivel: b.lotesQuadra.filter((x) => x.disponivel).length,
        };
      }),
    };
  };

  render() {
    return (
      <div className={styles["Painel"]}>
        {/* <Loading isLoading={this.props.isLoading}>
          <div className={styles["content"]}>
            <div className={styles["table"]}>
              <TableComponent {...this.configTable()} />
            </div>
          </div>
        </Loading> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { quadras } = state;
  return {
    quadras,
    isLoading: quadras.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchQuadras: QuadrasActions.fetchQuadras,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Painel);
