import { ImobiliariaActionUnion, ImobiliariaActionKeys } from './action';
import { IImobiliariaState } from './IImobiliariaState';

const initialState: IImobiliariaState = {
  data: {
    data: {
      data: []
    },
    success: false
  },
  error: {
    data: [],
    success: false
  },
  isLoading: false
};

const imobiliariaReducer = (state = initialState, action: ImobiliariaActionUnion): IImobiliariaState => {
  switch (action.type) {
    case ImobiliariaActionKeys.FETCH_IMOBILIARIA_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case ImobiliariaActionKeys.FETCH_IMOBILIARIA_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case ImobiliariaActionKeys.FETCH_IMOBILIARIA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
};

export default imobiliariaReducer;
