import React, { FC } from 'react';
import { ITHead, IColumunStatusValue } from '../Interfaces/ITHead';
import { TableRow, TableCell } from '@material-ui/core';
import styles from '../TableComponents.module.scss';

export interface ITableRowComponent {
  HeadData: ITHead[];
  className?: string;
  item: any;
  index: number;
  isChildren: boolean;
  getItemName: Function;
  clickRow?: (item: any) => void;
}

export const getIColumnStatusValueString = (value: IColumunStatusValue) => {
  switch (value) {
    case IColumunStatusValue.active:
      return 'active';
    case IColumunStatusValue.negociacao:
      return 'black';
    case IColumunStatusValue.multiplos:
      return 'black-opacity';
    case IColumunStatusValue.success:
      return 'success';
    case IColumunStatusValue.warning:
      return 'warning';
    case IColumunStatusValue.recusado:
      return 'error';
    default:
      break;
  }
};

const TableRowComponent: FC<ITableRowComponent> = ({
  HeadData,
  item,
  index,
  getItemName,
  clickRow,
  className
}) => {
  const getItemStatusName = (head: ITHead, item: any) =>
    item[head.columnLabelValue.trim()];

  const getItemStatusValue = (head: ITHead, item: any) => {
    const result = item[head.columnStatusValue.trim()] as IColumunStatusValue;
    return getIColumnStatusValueString(result);
  };

  return (
    <TableRow key={`row_component_${index}`} className={`${styles['row']} ${className}`}>
      {HeadData.map((head, idx) => {
        return head.isStatusColumn === true ? (
          <TableCell
            key={`cell_${idx}`}
            component="th"
            className={`${styles['row-cell-item']} ${
              item['borderLeft'] && idx === 0 ? styles['leftBorder'] : ''
              }`}
            scope="row"
          >
            <span className={styles[`color-${getItemStatusValue(head, item)}`]}>
              {getItemStatusName(head, item)}
            </span>
          </TableCell>
        ) : (
            <TableCell
              key={`cell_${idx}`}
              component="th"
              className={`${styles['row-cell-item']} ${
                item['borderLeft'] && idx === 0 ? styles['leftBorder'] : ''
                } ${clickRow && styles['click-row']}`}
              scope="row"
              onClick={() => clickRow && clickRow(item)}
            >
              {head.isImage ? (
                <img alt="Logo" src={getItemName(head.name, item)} className={styles['image']} />
              ) : getItemName(head.name, item)}
            </TableCell>
          );
      })}
    </TableRow>
  );
};

export default TableRowComponent;
